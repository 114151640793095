import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import SnackbarContent from '@material-ui/core/SnackbarContent';

export default function SnackBarWarning(props) {
  const [open, setOpen] = React.useState(true);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={() => {setOpen(false); props.onClose();}}
      >
        <SnackbarContent
          aria-labelledby={props.aria}
          className="snackbar-warning-content"
          message={
            <div className="snackbar-close" aria-label="Close" onClick={() => {setOpen(false); props.onClose();}}>
              <div>
                <ErrorIcon className="mr-2"/>
                {props.message}
              </div>
              <CloseIcon className="snackbar-close-icon"/>
            </div>
          }
        />
      </Snackbar>
    </div>
  );
}
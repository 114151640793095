import React, { Component } from 'react';
import AppContext from '../AppContext';
import RequirementQuestion from '../RequirementQuestion';

class JobPositions extends Component {

  returnJobFamilyOptions(positions) {
    let options =[];
    for (const position of positions) {
      options.push({
        label: position.jobFamily, 
        value: 'selectedJobPosition', 
        response: position
      });
    }
    let sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));
    return sortedOptions;
  }

  returnDefaultValue(stateValue, options) {
    if (stateValue) {
      const positionOptions = this.returnJobFamilyOptions(options);
      const optionToReturn = positionOptions.find(option => option.label === stateValue.jobFamily);

      return optionToReturn;
    } else {
      return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        <AppContext.Consumer>
          {(context) => 
            <React.Fragment>
              {context.state.jobPositions &&
                <React.Fragment>
                  <RequirementQuestion
                    requirementResponse={false}
                    options={this.returnJobFamilyOptions(context.state.jobPositions)}
                    question="What is the job family of the position* which the candidate is applying for?"
                    description='<p>Please begin typing the Google job family to see options. Check go/activejobcodes if there are any questions, 
                    otherwise contact <a href="mailto:immigration@google.com">immigration@google.com</a> for a custom review of any unlisted job family.
                    <br/>
                    <br/>
                    Please make sure to select the correct job family as job titles do not always map to the most obvious job family. For example, most Program Manager roles do not map to the job family "Program Manager", which is a specific role within Eng.
                    </p>'
                    dropdown={true}
                    placeholder={'Select Position'}
                    defaultValue={this.returnDefaultValue(context.state.selectedJobPosition, context.state.jobPositions)}
                    id="select-job-position"
                    label="Select job position"
                    onClickContinue ={() => {
                      this.props.updateSelectingState('isSelectingJobPosition', false);
                      this.props.updateSelectingState('isSelectingCitizenship', true);
                      this.props.updateSelectingState('stepIndex', 3);
                    }
                    }
                    onClickBack= {() => {
                      this.props.updateSelectingState('isSelectingJobPosition', false);
                      this.props.updateSelectingState('isSelectingSource', true);
                      this.props.updateSelectingState('stepIndex', 1);
                    }
                    }
                    footnote="*Note: VisaCheck is currently for full-time roles only, not internships, FTC, business visas, rotations, etc."
                  />
                </React.Fragment>
              }
            </React.Fragment>
          }
        </AppContext.Consumer>
      </React.Fragment>
    );
  }
}

export default JobPositions;

import React, { Component } from 'react';
import AppContext from '../AppContext';
import SelectDestinationCountry from '../SelectDestinationCountry';
import Loading from '../Loading';
import Refresh from '@material-ui/icons/Refresh';

class LandingPage extends Component {
  componentDidMount() {
    this.props.resetAssessment();
  }

  beginAssessment = () => {
    window.location.assign('/#/assessment');
  }
  
  render() {
    return (
      <AppContext.Consumer>
        {(context) =>   
          <div className={(this.props.logIn ? 'log-in-screen ' : '') + 'landing-page-container d-flex justify-content-center align-items-center flex-column'}>
            <h1 className="landing-page-title pt-5 mb-3">
              Google VisaCheck
            </h1>
            {this.props.logIn
              ? // if the user is not logged in.
              <React.Fragment>
                {
                //if domain is not forbidden.
                !this.props.isForbidden 
                //Google sign in button
                  ? <div id='signInDiv'></div>
                  :<React.Fragment>
                      <div className="landing-page-text pt-4 pb-2">
                      Restricted email domain, access forbidden. Please contact administrator.
                      </div>
                      <div className="d-flex retry-login" onClick={() => {this.props.logout(true);}}>
                        <Refresh className="retry-login-icon"></Refresh>
                        <div className="retry-login-text">TRY AGAIN</div>
                      </div>
                    </React.Fragment>
                }
              </React.Fragment>
              :
              <React.Fragment>
                {(context.state.destinationCountries.length > 0) ?
                  <React.Fragment>
                    <SelectDestinationCountry/>
                    <button 
                        disabled={!context.state.selectedDestinationRegion} 
                        className="btn landing-page-button" 
                        onClick={() => {this.beginAssessment(); context.updateState('isInResultsPage', false);}}
                      >
                        BEGIN ASSESSMENT
                      </button>
                  </React.Fragment>
                  :
                  <Loading />
                }
              </React.Fragment>
            }
          </div>
        }
      </AppContext.Consumer>
    );
  }
}

export default LandingPage;

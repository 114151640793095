import React, {Component} from 'react';
import Select, {components} from 'react-select';
import AppContext from '../AppContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class ExistingVisaQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: 0,
      value: null
    };

  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.question !== this.props.question) {
      this.setState({ value: null });
    }
  }

  updateValue =(event)=> {
    this.setState({ value: event });
  }

  render() {
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <ExpandMoreIcon className={(this.props.disabled ? 'disabled ' : '') + 'blue-chevron'}/>
        </components.DropdownIndicator>
      );
    };

    return (
      <AppContext.Consumer>
        {(context) =>
          <div className="row d-flex align-items-center vc__existing-visa-node">
            <div className="col-9 offset-1">
              <h1 className="requirement-name">Existing Visa Review</h1>
              <h1 className="vc__requirement-question mb-3 mt-3">{this.props.question}</h1>
              <p className="requirement-description mb-5" dangerouslySetInnerHTML={{__html: this.props.description}} ></p>

              {/* The dropdown for the existing visas */}
              <div className="mt-5" onClick={() => this.setState(prevState => ({ refresh: prevState.refresh++ }))}>
                <Select
                  value={this.state.value}
                  inputId={this.props.id}
                  className="requirement-react-select-container"
                  classNamePrefix="requirement-react-select"
                  components={{DropdownIndicator}}
                  options={this.props.options}
                  onChange={ (event) => {
                    if (!this.props.onChange) {
                      context.updateState(event.value, event.response);
                      this.updateValue(event);
                    } else {
                      this.props.onChange(event);
                      this.updateValue(event);
                    }
                  }}
                />
              </div>
            </div>

            <div className="col-9 offset-1">
              {/* The 'Back' and 'Continue' buttons */}
              <div className="d-flex justify-content-between">
                <button
                  aria-label="End assessment button"
                  onClick={
                    this.props.onClickBack
                      ? (event) => {
                        event.preventDefault();
                        this.props.onClickBack();
                      }
                      : null}
                  className={`btn btn-link requirement-back-link ${this.props.onClickBack ? '' : ' hide'}`}
                >
                  BACK
                </button>
                <button
                  aria-label="Continue to next question"
                  disabled={!this.state.value}
                  type="button"
                  className="requirement-question-button"
                  onClick={this.props.onClickContinue}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        }
      </AppContext.Consumer>
    );
  }
}

export default ExistingVisaQuestion;

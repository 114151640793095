import axios from 'axios';

export function plainApi() {
  return axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? 'http://google.wapa.deloitte:9090' : '/api',
    withCredentials: true, // allow axios to include credentials so cookies created in the backend can be seen by the browser.
    'Cache-Control': 'no-cache',
  });
}

/**
 * Sends to session expired page which logs the user out.
 */
async function sessionExpired() {
  localStorage.removeItem('image');
  window.location.replace('/#/expired');
}

export function authApi() {
  const api = plainApi();
  // /*global google*/
  api.interceptors.response.use(
    response => response,
    error => {
      try{
      if (error && (error.response.status === 403 || error.response.status === 401)) {
        sessionExpired();
      }}catch(e){
        sessionExpired();
        console.log('error in api call', e);
      }
    }
  );

  return api;
}

import React, { Component } from 'react';
import AppContext from '../AppContext';
import RequirementQuestion from '../RequirementQuestion';
import SnackBarWarning from '../SnackBarWarning';
import Lock from '@material-ui/icons/Lock';

class Summary extends Component {
  returnRequirementOptions(index, requirement, salaryOptions){
    let options =[];

    if (requirement.type === 'salaryThreshold') {
      options = salaryOptions;
    } else {
      for(const value of requirement.values){
        if(value.requirementValueRevisions[0].value !== 'Not Required'){
          options.push({
            label: value.requirementValueRevisions[0].value,
            value: ('requirement'+ value.id),
            response: {index: index, requirement: requirement, value: value}});
        }
      }
    }
    return options;
  }

  returnDefaultRequirementValue (response, stateRequirementValue, index){
    if(stateRequirementValue.type !== 'numeric') {
      if(response.value.value) {
        return this.returnSelectedOption(response.value, stateRequirementValue, index);
      }
    } else {
      if(response.value.value) {
        return response.value.value;
      }
    }
  }

  returnSelectedOption(responseValue, stateRequirementValue, index) {
    let selectedValue = stateRequirementValue.values.find(value => value.id === responseValue.id);
    let selectedOption = null;

    if (selectedValue) {
      selectedOption = {
        label: selectedValue.requirementValueRevisions[0].value,
        value: ('requirement'+ selectedValue.id),
        response: {index: index, requirement: stateRequirementValue, value:selectedValue}
      };
    } else {
      selectedOption = {
        label: responseValue.label,
        value: 'requirement' + responseValue.value,
        // response: { index: index, requirement: stateRequirementValue, value: selectedValue },
      };
    }

    return selectedOption;
  }

  returnCountryOptions(name, destinationCountries){
    let options =[];
    for(const country of destinationCountries){
      for(const region of country.regions){
        options.push({
          label: country.regions.length > 1 ? (country.name + ' - ' + region.name) : (country.name),
          value: name,
          response: {region: region, country: country}});
      }
    }
    return options;
  }

  returnCitizenshipOptions(name, citizenships) {
    let options = [{
      label: 'Not disclosed',
      value: 'Not disclosed',
      response: {
        region: {
          id: 0,
          name: 'Not disclosed'
        },
        country: {
          id: 0,
          name: 'Not disclosed'
        },
      },
    }];

    for (const country of citizenships) {
      for (const region of country.regions) {
        options.push({
          label: country.regions.length > 1 ? (country.name + ' - ' + region.name) : (country.name),
          value: name,
          response: {region: region, country: country}
        });
      }
    }

    return options;
  }

  returnDefaultValue(stateValue, options) {
    if (stateValue) {
      const regionOptions = this.returnCountryOptions('selectedDestinationRegion', options);
      const optionToReturn = regionOptions.find(option => option.label.includes(stateValue));

      return optionToReturn;
    } else {
      return null;
    }
  }

  returnDefaultCitizenshipValue(stateValue, options) {
    if(stateValue){
      const regionOptions = this.returnCitizenshipOptions('selected', options);
      const optionToReturn = regionOptions.find(option => option.label.includes(stateValue));
      return optionToReturn;
    } else {
      return null;
    }
  }

  returnJobFamilyOptions(positions) {
    let options = [];

    for (const position of positions) {
      options.push({
        label: position.jobFamily,
        value: 'selectedJobPosition',
        response: position
      });
    }
    let sortedOptions = options.sort((a, b) => a.label.localeCompare(b.label));
    return sortedOptions;
  }

  returnDefaultJobValue(stateValue, options) {
    if (stateValue) {
      const positionOptions = this.returnJobFamilyOptions(options);
      const optionToReturn = positionOptions.find(option => option.label === stateValue.jobFamily);

      return optionToReturn;
    } else {
      return null;
    }
  }

  returnSelectedFieldOfStudy(response, fieldsOfStudy) {
    let isSelectedFieldOfStudyFound = fieldsOfStudy.filter(fos => fos.value === response.value.value)[0];

    if (isSelectedFieldOfStudyFound) {
      return {
        label: isSelectedFieldOfStudyFound.label,
        response: {
          id: response.value.id,
          type: response.value.type,
          value: response.value.value,
        },
        value: isSelectedFieldOfStudyFound.value,
      };
    }
  }

  returnSelectedJobLevel(response, jobLevel) {
    let isSelectedJobLevelFound = jobLevel.filter(level => level.value === response.value.value)[0];

    if (isSelectedJobLevelFound) {
      return {
        label: isSelectedJobLevelFound.label,
        response: {
          id: response.value.id,
          type: response.value.type,
          value: response.value.value,
        },
        value: isSelectedJobLevelFound.value,
      };
    }
  }

  buildVisaDropdown(name, citizenshipSpecificVisas) {
    let options = [];

    for (const visa of citizenshipSpecificVisas) {
      const currentVisaName = visa.name.trim();
      if(!options.filter(option => option.responseValue === currentVisaName).length > 0) {
        const optionLabel = currentVisaName + ' - ' + visa.citizenships.join(', ');
        options.push({
          label: optionLabel,
          value: name,
          responseValue: currentVisaName, // Can be changed to ID once work permits become grouped
        });
      }
    }

    options.push({
      label: 'None of the above',
      value: 'N/A',
      responseValue: 'N/A', // Can be changed to ID once work permits become grouped
    });

    return options;
  }
  //check for character limitation
  limitReached = false;
  render() {
    return (
      <AppContext.Consumer>
        {(context) => {
          //check if the limit is already reached when loaded
          if(context.state.gHireId) this.limitReached = context.state.gHireId.length >= window.$textFieldMaxLength;
          return(
            <div className="requirement-summary-container container d-flex vc__requirement-animation">
              <div className ="row w-100 d-flex">
                <div className="col-1"></div>
                <div className="col-11">
                  <p className="requirement-summary">Review & Submit</p>
                </div>
              </div>
              <div className ="row w-100 d-flex">
                <div className="col-1 d-flex justify-content-center align-items-center">
                  <Lock className="requirement-question-lock"/>
                </div>
                <div className="col-11">
                  <RequirementQuestion
                    groupTop= {true}
                    options = {this.returnCountryOptions('selectedSourceRegion', context.state.countries)}
                    name="Destination Country"
                    requirementResponse={false}
                    dropdown={true}
                    defaultValue={context.state.selectedDestinationRegion ? this.returnDefaultValue(context.state.selectedDestinationRegion.country.name, context.state.countries) : null}
                    text = {false}
                    id={'enter-destination-country'}
                    label="please-enter-destination-country"
                    summary={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className ="row w-100 d-flex">
                <div className="col-1 d-flex justify-content-center align-items-center">
                  <Lock className="requirement-question-lock"/>
                </div>
                <div className="col-11">
                  <RequirementQuestion
                    groupTop= {true}
                    options = {[{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}]}
                    name="Existing Visa"
                    requirementResponse={false}
                    dropdown={true}
                    defaultValue={context.state.isExistingVisaHolder ? {label: 'Yes', value: 'Yes'}: {label: 'No', value: 'No'}}
                    text = {false}
                    id={'enter-existing-visa'}
                    label="please-enter-existing-visa"
                    summary={true}
                    disabled={true}
                  />
                </div>
              </div>
              <div className ="row w-100 d-flex">
                <div className="col-1"></div>
                <div className="col-11">
                  <RequirementQuestion
                    placeholder={'Enter Country'}
                    groupTop= {true}
                    options = {this.returnCountryOptions('selectedSourceRegion', context.state.countries)}
                    name="Source Country"
                    requirementResponse={false}
                    dropdown={true}
                    defaultValue={context.state.selectedSourceRegion ? (this.returnDefaultValue(context.state.selectedSourceRegion.country.name, context.state.countries)) : null}
                    text = {false}
                    id={'enter-source-country'}
                    label="please-enter-source-country"
                    summary={true}
                  />
                </div>
              </div>
              <div className ="row w-100 d-flex">
                <div className="col-1"></div>
                <div className="col-11">
                  <RequirementQuestion
                    placeholder={'Enter Position'}
                    groupTop= {true}
                    options={this.returnJobFamilyOptions(context.state.jobPositions)}
                    name="Job Position"
                    requirementResponse={false}
                    dropdown={true}
                    defaultValue={this.returnDefaultJobValue(context.state.selectedJobPosition, context.state.jobPositions)}
                    text = {false}
                    id={'enter-job-position'}
                    label="please-enter-job-position"
                    summary={true}
                  />
                </div>
              </div>
              {/* citizenship field */}
              <div className ="row w-100 d-flex">
                <div className="col-1"></div>
                <div className="col-11">
                  <RequirementQuestion
                    placeholder={'Enter Citizenship'}
                    groupTop= {true}
                    options = {this.returnCitizenshipOptions('selectedCitizenship', context.state.countries)}
                    name="Citizenship"
                    requirementResponse={false}
                    dropdown={true}
                    defaultValue={context.state.selectedCitizenship ? (this.returnDefaultCitizenshipValue(context.state.selectedCitizenship.country.name, context.state.countries)) : null}
                    text = {false}
                    id={'enter-citizenship'}
                    label="please-enter-citizenship"
                    summary={true}
                    onChange={ (event) => {
                      context.updateState('selectedCitizenship', event.response);
                      context.updateState('selectedCitizenshipSpecificVisa', null);
                    }}
                  />
                  {(context.state.selectedCitizenship && 
                    context.state.selectedCitizenship.country.name === 'Not disclosed' && context.state.selectedDestinationRegion.country.id === 1) &&
                    <RequirementQuestion
                      placeholder={'Enter Visa'}
                      groupTop= {true}
                      noPadding={false}
                      name="Citizenship specific Visa"
                      options = {this.buildVisaDropdown('selectedCitizenshipSpecificVisa', context.state.citizenshipSpecificVisas)}
                      requirementResponse={false}
                      dropdown={true}
                      defaultValue={context.state.selectedCitizenshipSpecificVisa}
                      text = {false}
                      id="select-citizenship-visa"
                      label="Select Citizenship Visa"
                      summary={true}
                      onChange={ (event) => {
                        context.updateState('selectedCitizenshipSpecificVisa', event);
                      }}
                  />
                  }
                </div>
              </div>

              {/* custom requirements */}
              { context.state.requirements.map((requirement, index) => {
                return (
                  <div className ="row w-100 d-flex">
                    <div className="col-1"></div>
                    <div className="col-11">
                      {
                        requirement.type !== 'fieldOfStudy' && requirement.type !== 'jobLevel'
                          ? <RequirementQuestion
                            requirementResponse={true}
                            groupTop= {true}
                            summary={true}
                            placeholder={'Enter ' + requirement.requirementRevisions[0].name}
                            options={this.returnRequirementOptions(this.props.index, requirement, context.state.salaryOptions)}
                            name={requirement.requirementRevisions[0].name}
                            prefix={requirement.requirementRevisions[0].prefix}
                            type={requirement.type}
                            dropdown={requirement.type.includes('Select') || requirement.type.includes('salary') || requirement.type.includes('JobPosition')}
                            defaultValue={this.returnDefaultRequirementValue(context.state.responses[index],requirement, index)}
                            id={'enter-'+ requirement.name}
                            label={'Please enter or select '+ requirement.name}
                            onChange= {requirement.type !== 'numeric' ? (event) => {context.updateRequirement(event, index);} : (event, index, requirement) => {context.updateNumericValue(event, index, requirement);}}
                          />
                          : requirement.type === 'fieldOfStudy'
                            ? <RequirementQuestion
                                requirementResponse={true}
                                groupTop= {true}
                                summary={true}
                                type={requirement.type}
                                placeholder={'Enter ' + requirement.requirementRevisions[0].name}
                                options={context.state.fieldsOfStudyOptions}
                                name={requirement.requirementRevisions[0].name}
                                prefix={requirement.requirementRevisions[0].prefix}
                                dropdown={true}
                                defaultValue={this.returnSelectedFieldOfStudy(context.state.responses[index], context.state.fieldsOfStudyOptions)}
                                id={'enter-'+ requirement.name}
                                label={'Please enter or select '+ requirement.name}
                                onChange={event => context.updateFieldOfStudy(event, index, requirement)}
                              />
                            : // Job Level Field
                              <RequirementQuestion
                              requirementResponse={true}
                              groupTop= {true}
                              summary={true}
                              type={requirement.type}
                              placeholder={'Enter ' + requirement.requirementRevisions[0].name}
                              options={context.state.jobLevelOptions}
                              name={requirement.requirementRevisions[0].name}
                              prefix={requirement.requirementRevisions[0].prefix}
                              dropdown={true}
                              defaultValue={this.returnSelectedJobLevel(context.state.responses[index], context.state.jobLevelOptions)}
                              id={'enter-'+ requirement.name}
                              label={'Please enter or select '+ requirement.name}
                              onChange={event => context.updateJobLevel(event, index, requirement)}
                            />
                      }
                    </div>
                  </div>
                );
              }) }
              <div className ="row w-100 d-flex">
                <div className="col-11 offset-1">
                  <RequirementQuestion
                    limitReachMessage={this.limitReached ? 'Maximum character limit reached for this field.' : ''}
                    groupTop= {true}
                    summary={true}
                    placeholder={'Enter gHire App ID'}
                    name="Ghire App ID (Optional)"
                    requirementResponse={false}
                    dropdown={false}
                    defaultValue={context.state.gHireId}
                    text = {true}
                    id={'enter-ghire-id'}
                    label="please-enter-ghire-id-if-applicable"
                    onChange={(event) => {context.updateState('gHireId', event.target.value); this.limitReached = (event.target.value.length >= event.target.maxLength);}}
                  />
                </div>
              </div>
              <form>
                <div className="col-11 offset-1 pl-0">
                  <button onClick={(event) => {context.submitResponses(event); context.updateState('isInResultsPage', true);}} className="requirement-question-button ml-auto mr-auto mt-5 mb-5">SUBMIT</button>
                </div>
              </form>
              {(!context.state.responsesValid || !context.state.isCompletedExistingVisa) &&
                  <SnackBarWarning onClose={() => context.updateState('responsesValid', true)} message={!context.state.responsesValid ? 'Please fill all non-optional fields' : 'Please complete existing visa section'} aria="Please fill all fields"></SnackBarWarning>
              }
            </div>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default Summary;

import React, {Component} from 'react';
import Select, {components} from 'react-select';
import AppContext from '../AppContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

window.$textFieldMaxLength = 255;

class RequirementQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: 0,
      value: null
    };

  }
  componentDidUpdate = (prevProps) => {
    if (prevProps.question !== this.props.question) {
      this.setState({
        value: null
      });
    }
  }

  updateValue =(event)=> {
    this.setState({
      value: event
    });
  }

  render() {
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <ExpandMoreIcon className={(this.props.disabled ? 'disabled ' : '') + 'blue-chevron'}/>
        </components.DropdownIndicator>
      );
    };

    // Update the color of the description in the react-select for the fields of study question
    if (this.props.type === 'fieldOfStudy') {
      // Covers the first option in the lsit, which has a different class
      let initialItems = document.getElementsByClassName('requirement-react-select__option requirement-react-select__option--is-focused css-1n7v3ny-option');
      for (const initialItem of initialItems) {
        let previousHTMLSplit = initialItem.innerHTML.split(' - ');
        let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
        initialItem.innerHTML = boldText + ' - ' + previousHTMLSplit[1];
      }

      // Covers the selected option
      let selectedItems = document.getElementsByClassName('requirement-react-select__option requirement-react-select__option--is-selected css-9gakcf-option');
      for (const selectedItem of selectedItems) {
        let previousHTMLSplit = selectedItem.innerHTML.split(' - ');
        let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
        selectedItem.innerHTML = boldText + ' - ' + previousHTMLSplit[1];
      }

      // Covers every other option
      let regularItems = document.getElementsByClassName('requirement-react-select__option css-yt9ioa-option');
      for (const regularItem of regularItems) {
        let previousHTMLSplit = regularItem.innerHTML.split(' - ');
        let boldText = '<span style="color: #000000">' + previousHTMLSplit[0] + '</span>';
        regularItem.innerHTML = boldText + ' - ' + previousHTMLSplit[1];
      }
    }

    return (
      <AppContext.Consumer>
        {(context) =>
          <div className={'d-flex justify-content-center m-auto requirement-question-container ' +
            (this.props.groupTop && !this.props.noPadding ? 'requirement-group-top' : '') +
            (this.props.groupBottom ? 'requirement-group-bottom': '') +
            (this.props.requirementResponse ? ' response': '') +
            (this.props.noPadding ? 'requirement-group-top-remove-padding' : '')
          }>
            <form className={'vc__requirement-animation-wrapper' + (!this.props.summary ? ' vc__requirement-animation' : '')}>
              <div className="form-group">
                {this.props.name &&
                  <h1 className="requirement-name">{this.props.name}</h1>
                }
                {!this.props.groupBottom && !this.props.summary &&
                  <div className="d-flex">
                    <h1 className="vc__requirement-question mb-3 mt-3">{this.props.question}</h1>
                  </div>
                }
                {!this.props.summary &&
                  <p dangerouslySetInnerHTML={{ __html: this.props.description }} className="requirement-description mb-1"></p>
                }
                <label htmlFor={this.props.id} className="requirement-label">{this.props.label}</label>
                {this.props.dropdown && !this.props.customInput &&
                  <div onClick={() => this.setState(prevState => ({ refresh: prevState.refresh++ }))}>
                    <Select
                      value={this.props.defaultValue ? this.props.defaultValue : this.state.value}
                      placeholder={this.props.placeholder}
                      inputId={this.props.id}
                      className="requirement-react-select-container"
                      classNamePrefix="requirement-react-select"
                      components={{DropdownIndicator}}
                      options={this.props.options}
                      onChange={ (event) => {
                        if (!this.props.onChange) {
                          context.updateState(event.value, event.response);
                          this.updateValue(event);
                        } else {
                          this.props.onChange(event);
                          this.updateValue(event);
                        }
                      }}
                      defaultValue={this.props.defaultValue}
                      isDisabled={this.props.disabled === true}
                    />
                  </div>
                }
                {!this.props.dropdown && !this.props.customInput &&
                //added the fragment tag to add the extra span for the error hint message at the end
                <>
                  <div className="numeric-input-container d-flex">
                    <span className="numeric-requirement-prefix">{this.props.prefix}</span>
                    <input
                      id={this.props.id}
                      maxLength={this.props.maxLength||window.$textFieldMaxLength}
                      className="requirement-numeric-input"
                      value={this.props.defaultValue}
                      placeholder={this.props.placeholder ? this.props.placeholder : 'Enter Value'}
                      type={this.props.text ? 'text' : 'number'}
                      onChange={this.props.onChange}
                    />
                  </div>
                  {/* Hint message for errors on the field */}
                  <span className='error-hint-message'>{this.props.limitReachMessage}</span>
                </>
                }
                {this.props.customInput &&
                  <div>
                    {this.props.children}
                  </div>
                }
              </div>
              {!this.props.groupTop && !this.props.customInput &&
                <div className="d-flex justify-content-between mt-5 mb-4 w-100">
                  <button aria-label="End assessment button" onClick={this.props.onClickBack ? (event) => {event.preventDefault(); this.props.onClickBack();} : null} className={'btn btn-link requirement-back-link' + (this.props.onClickBack ? '' : ' hide')}>BACK</button>
                  <button aria-label="Continue to next question" disabled={this.props.disableContinue} type="button" className="requirement-question-button" onClick={this.props.onClickContinue}>CONTINUE</button>
                </div>
              }
              {this.props.footnote &&
                <div className="vc__requirement-footnote">{this.props.footnote}</div>
              }
            </form>
          </div>
        }
      </AppContext.Consumer>
    );
  }
}

export default RequirementQuestion;

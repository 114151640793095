import React, { Component, Fragment } from 'react';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffOutlined from '@material-ui/icons/HighlightOffOutlined';
import WorkOutlineOutlined from '@material-ui/icons/WorkOutlineOutlined';
import ResultsIneligiblePermits from '../ResultsIneligiblePermits';
import AppContext from '../AppContext';

class ResultsEligibility extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {(context) =>
          <Fragment>
            {
              context.state.isAssessmentComplete
              ? <Fragment>
                  <div className="d-flex align-items-center">
                    {
                      context.state.eligibilityResult.length > 0 && context.state.eligibilityResult[0] != null
                      ? <h1 className="results-eligibility-heading">Candidate is eligible</h1>
                      : <h1 className="results-eligibility-heading">Candidate is ineligible</h1>
                    }
                    {
                      context.state.eligibilityResult.length > 0 && context.state.eligibilityResult[0] != null
                      ? <CheckCircleOutlined className="results-success-check ml-3" />
                      : <HighlightOffOutlined className="results-failure-x ml-3" />
                    }
                    <button className="btn vc_results-return-button mt-1 vc_align-right" onClick={() => {window.location.replace('/#/assessment');}}>RETURN TO ASSESSMENT</button>
                  </div>
                  {
                    context.state.eligibilityResult.length > 0 && context.state.eligibilityResult[0] != null
                      ? <div>
                          <p className="results-eligibility-description pt-3 pb-4">
                            Please keep in mind that this assessment is based on the data provided. 
                            If the candidate applies to a different role, or any criteria change on their application, the results may differ. <br/> <br/>
                            All candidates who reach Offer Accept will receive a final visa assessment by Google’s Immigration counsel.
                          </p>
                          <hr/>
                        </ div>
                      : <Fragment>
                          <p className="results-eligibility-description pt-3 pb-4">
                            Please keep in mind that this assessment is based on the data provided. 
                            If the candidate applies to a different role, or any criteria change on their application, the results may differ. <br/><br/>
                            Use <a href='https://goto.google.com/howtoreject' target='_blank' rel='noopener noreferrer'><u>this guide</u></a>  to let the candidate know they, or the role, are ineligible for sponsorship. 
                            It includes resources and approved language, as well as guidance on how to handle any follow-up questions and concerns.
                          </p>
                          <div className="d-flex align-items-center">
                            <WorkOutlineOutlined className="results-permit-briefcase"></WorkOutlineOutlined>
                            { context.state.ineligibleResult.length <= 0 
                              ? <h6 className="ml-2 about-permit-heading">NO PERMIT MATCH FOUND</h6>
                              : context.state.ineligibleResult.length === 1
                                ? <h6 className="ml-2 about-permit-heading">CLOSEST PERMIT MATCH</h6>
                                : <h6 className="ml-2 about-permit-heading">CLOSEST PERMIT MATCHES</h6>
                            }
                          </div>
                        { context.state.ineligibleResult.length > 0 && 
                        <ResultsIneligiblePermits  ineligiblePermitsToShow={context.state.ineligibleResult.slice(0, 3)}/>}
                        </Fragment>
                  }
                </Fragment>
              :
                <div>
                  <div className="row d-flex align-items-center">
                    <h1 className="results-eligibility-heading">No results available</h1>
                    <HighlightOffOutlined className="results-failure-x ml-3" />
                  </div>
                  <div className="row">
                    <button className="btn vc_results-return-button mt-4" onClick={() => {context.resetAssessment(); window.location.replace('/#/');}}>RETURN TO ASSESSMENT</button>
                  </div>
                  <hr/>
                </div>
              }
          </Fragment>
        }
      </AppContext.Consumer>
    );
  }
}

export default ResultsEligibility;

import React from 'react';
import ResultsSummary from '../components/ResultsSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid #d1d1d1',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ResultsIneligiblePermits = ({ ineligiblePermitsToShow }) => (
  ineligiblePermitsToShow.length > 0 && 
    <div className="result-section-container mt-3 mb-5">
      {ineligiblePermitsToShow.map((ineligiblePermit, index) => (
        <ExpansionPanel defaultExpanded={index === 0 ? true : false} key={index}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="results-expand-more"/>}>
            <h6 className="results-sections-title pt-1">{ineligiblePermit.workPermit.workPermitRevisions[0].name}</h6>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className="results-description">
              <div className="content"><ResultsSummary index = {index} /></div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
  </div>
);

export default ResultsIneligiblePermits;
import React, { Fragment } from 'react';
import { logout } from '../../services/AuthenticationService';
// import {UserContext} from '../../contexts/UserContext.js';

export default function LogoutButton() {
  // const [userState, userDispatch] = useContext(UserContext);

  const handleLogout = async () => {
    await logout();
    // Add in after navbar is refactored.
    // userDispatch({
    //   type: 'LOGOUT',
    // });
  };

  return (
      <Fragment><a href="/#" onClick={handleLogout}>Logout</a></Fragment>
  );
}

import React from 'react';

class CopyText extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isCopied: false
    };
  }

  copyToClipboard = (e) => {
    this.textArea.select();
    document.execCommand('copy');
    e.target.focus();
    this.setState({ isCopied: true });
  };

  render() {
    return (
      <div>
        {
          document.queryCommandSupported('copy') &&
          <div>
            <button 
              className={'btn vc__white-button mt-4 mr-4 vc__copied-hidden vc__clipboard-button-size' + (this.state.isCopied === true ? ' transition' : '')}
              onClick={(event) => {this.copyToClipboard(event); this.props.trackAnalyticsEvent('click', this.props.eventName, 'button'); }}>
                { !this.state.isCopied
                ? 'COPY TO CLIPBOARD'
                : <div className="d-flex align-content-center">
                    <div className="vc__copy-text-animation mr-5">COPIED</div>
                    <svg viewbox="0 0 52 52" width="40" height="20">
                      <path vector-effect="non-scaling-stroke" id="check" d="M14.1 8.1l6.1 6.2 12.7-12.8"/>
                    </svg>
                  </div>
                }
            </button>
          </div>
        }
        <textarea
          aria-labelledby="copy-to-clipboard-button"
          label="copy-to-clipboard-text"
          className="copy-input-text-area"
          ref={(textarea) => this.textArea = textarea}
          value={this.props.textToCopy}
        />
      </div>
    );
  }

}

export default CopyText;

import React, {useState, useEffect} from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FilterListOutlined from '@material-ui/icons/FilterListOutlined';
import Select from 'react-select';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Fade from '@material-ui/core/Fade';
import AppContext from '../AppContext';
import ReportingTable from '../ReportingTable';
import Loading from '../Loading';
import {subDays} from 'date-fns';
import {format} from 'date-fns';

export default function Reporting(props) {
  const [selectedStartDate, handleStartDateChange] = useState(format(subDays(new Date(), 14),'yyyy-MM-dd'));
  const [selectedEndDate, handleEndDateChange] = useState(format(new Date(),'yyyy-MM-dd'));
  const [dateType, setDateType] = useState('range');
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [jobPositions, setJobPositions] = useState();

  useEffect(() => {
    props.updateAnalyticsPath('/reporting');
  }, [props]);


  function handleDateTypeChange(event) {
    setDateType(event.target.value);
  }

  function returnJobPositionOptions(jps) {
    let options =[];
    for (const jobPosition of jps) {
      options.push({
        label: jobPosition.jobFamily,
        value: jobPosition.id,
        position: jobPosition
      });
    }
    return options;
  }

  function handleJobPositionChange(event) {
    if(event) {
      setJobPositions(event.position);
    } else {
      setJobPositions(null);
    }
  }

  function returnReportingSubmission() {
    let reportingObject;

    if(dateType === 'all') {
      reportingObject = {
        dateTo: Date.now(),
        dateFrom: new Date('1994-07-01').getTime(),
        jobPosition: jobPositions
      };
    } else {
      reportingObject = {
        dateTo: (new Date(selectedEndDate)).getTime() + 24 * 60 * 60 * 1000,
        dateFrom: (new Date(selectedStartDate)).getTime(),
        jobPosition: jobPositions
      };
    }
    if (!jobPositions) {
      delete reportingObject.jobPosition;
    }

    return reportingObject;
  }

  return (
    <AppContext.Consumer>
      {(context) =>
        <div className="container pt-5">
          <ExpansionPanel expanded={isFilterOpen} className="reporting-filter-panel">
            <ExpansionPanelSummary onClick={() => setFilterOpen(!isFilterOpen)} className="reporting-filter-title">
              <FilterListOutlined className="mr-2"/>
              <p className="reporting-filter-text-top">Filter</p>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="reporting-panel-details">
              <div className="w-100 row">
                <div className="col-md mb-5">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <FormControl component="fieldset">
                      <h6 className="reporting-filter-text mt-2">Filter by date: </h6>
                      <RadioGroup className="ml-2" aria-label="gender" name="gender1" value={dateType} onChange={handleDateTypeChange}>
                        <FormControlLabel className="reporting-filter-text" value="all" control={<Radio className="reporting-radio" />} label="All Time" />
                        <FormControlLabel className="reporting-filter-text" value="range" control={<Radio className="reporting-radio"/>} label="Select Date Range" />
                      </RadioGroup>
                    </FormControl>
                    {dateType !== 'all' &&
                        <Fade timeout={400} in={dateType !== 'all'}>
                          <div className="d-flex">
                            <div className="mr-3 reporting-filter-picker-text">
                              <p className="mb-3 reporting-filter-picker-text">From:</p>
                              <div className="d-flex justify-content-center reporting-date-filter">
                                <label htmlFor="select-from-date" id="select-from-date-button" className="hidden-label">Select the date from which you would like to run the report</label>
                                <KeyboardDatePicker
                                  KeyboardButtonProps={{'aria-labelledby':'select-from-date-button'}}
                                  value={selectedStartDate}
                                  onChange={date => handleStartDateChange(format(date,'yyyy-MM-dd'))}
                                  maxDate={new Date()}
                                  id="select-from-date"
                                />
                                {selectedStartDate.toLocaleString().split(',')[0]}
                              </div>
                            </div>
                            <div className="reporting-filter-picker-text">
                              <p className="mb-3 reporting-filter-picker-text">To:</p>
                              <div className="d-flex justify-content-center reporting-date-filter">
                                <label htmlFor="select-to-date" id="select-to-date-button" className="hidden-label">Select the date to which you would like to run the report</label>
                                <KeyboardDatePicker
                                  KeyboardButtonProps={{'aria-labelledby':'select-to-date-button'}}
                                  value={selectedEndDate}
                                  onChange={date => handleEndDateChange(format(date,'yyyy-MM-dd'))}
                                  minDate={selectedStartDate}
                                  maxDate={new Date()}
                                  id="select-to-date"
                                />
                                {selectedEndDate.toLocaleString().split(',')[0]}
                              </div>
                            </div>
                          </div>
                        </Fade>
                    }
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-md">
                  <h6 className="reporting-filter-text pb-1 mr-2">Filter by job position: </h6>
                  <Select styles={{placeholder: (provided) => ({...provided, color: '#4f4f4f'})}} aria-label={'Select job position to filter by'} isClearable={true} placeholder="Select Job Position" className="reporting-filter-select mr-1 mb-3" options={returnJobPositionOptions(context.state.jobPositions)} onChange={(event) => handleJobPositionChange(event)}/>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button className="btn reporting-submit-filter mt-4" onClick={() => {setFilterOpen(!isFilterOpen); context.fetchReportingResults(returnReportingSubmission());}}>SUBMIT FILTERS</button>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          {context.state.reportingResults ?
            <ReportingTable reportingResults={context.state.reportingResults}/>
            :
            <Loading/>
          }
        </div>
      }
    </AppContext.Consumer>
  );
}

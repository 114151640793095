import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import 'animate.css';
import 'typeface-roboto';

import React, {useEffect, useReducer, useState} from 'react';
import {Route, HashRouter} from 'react-router-dom';

import LandingPage from './components/LandingPage';
import NavigationBar from './components/NavigationBar';
import Assessment from './components/Assessment';
import Results from './components/Results';
import Reporting from './components/Reporting';
import SessionExpired from './components/SessionExpired';
// import SessionManager from './components/SessionManager';

import AppProvider from './components/AppProvider';
import AppContext from './components/AppContext';
import { 
  logout, 
  initialiseGoogleAuthentication ,
} from './services/AuthenticationService';
import { UserContext } from './contexts/UserContext';
import { UserReducer } from './reducers/UserReducer';
const App = () => {
  const initialUserState = {
    // TODO this can eventually be removed once AppContext is refactored. Cookie will still log user out if for some reason the image remains in localstorage.
    isLoggedIn: localStorage.getItem('image') !== null, // Prevent the sign in page from appearing if already logged in even on refresh. Refresh needed for componentDidMount in AppContext.
    isForbidden: false,
  };
  const userReducer = (state, action) => UserReducer(state, action);
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);

  const [initialRefresh, setInitialRefresh] = useState(true);
  useEffect(() => {
    //set timeout here and check if Google is available
    document.getElementById('google-script').addEventListener('load', () => {
      // google-script is loaded
      console.log('google script load finished');
    });
    initialiseGoogleAuthentication();
    try{
      if(!window.google){
        setTimeout(googleLoginButton, 1000);
      }else {
        googleLoginButton();
      }
    }catch(e){
      setTimeout(googleLoginButton, 1000);
      console.log('google not loaded');
    }
    function googleLoginButton() {
      if(!userState.isLoggedIn){
        window.google && window.google.accounts.id.prompt();
        window.google && window.google.accounts.id.renderButton(document.getElementById('signInDiv'), {
          theme: 'outline',
          size: 'large',
        });
      }
    } 
      // Initialize the Google Authentication - only if user is not already signed in.
    setTimeout(() => setInitialRefresh(!initialRefresh), 1000);
    // } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    return (
      <React.Fragment>
        <AppProvider>
          <UserContext.Provider value={[userState, userDispatch]}>
          <AppContext.Consumer>
            {(context) =>
            <React.Fragment>
              {/* <SessionManager />  */}
              {/* TODO - If session extension ever gets added */}
              { userState.isLoggedIn  && !userState.isForbidden
              ?
              <React.Fragment>
                <HashRouter basename="/">
                  <NavigationBar/>
                  <div className="app-container">
                    <Route exact path="/" render={() => <LandingPage resetAssessment={context.resetAssessment}/>}/>
                    <Route exact path="/assessment" render={() => <Assessment updateAnalyticsPath={context.updateAnalyticsPath} requirements={context.state.requirements} responses={context.state.responses} selectedJobPosition={context.state.selectedJobPosition} selectedSourceRegion={context.state.selectedSourceRegion} selectedDestinationRegion={context.state.selectedDestinationRegion} updateState={context.updateState}/>}/>
                    <Route exact path="/results" render={() => <Results updateAnalyticsPath={context.updateAnalyticsPath} />}/>
                    <Route exact path="/reporting" render={() => <Reporting updateAnalyticsPath={context.updateAnalyticsPath} />}/>
                    <Route exact path="/expired" render={() => <SessionExpired updateAnalyticsPath={context.updateAnalyticsPath} isValidSession={userState.isLoggedIn} />}/>
                  </div>
                </HashRouter>
              </React.Fragment>
              :
                  <LandingPage
                    logout={logout}
                    logIn={!userState.isLoggedIn}
                    isForbidden={userState.isForbidden}
                    resetAssessment={context.resetAssessment}
                    />
            }
          </React.Fragment>
        }
        </AppContext.Consumer>
        </UserContext.Provider>
        </AppProvider>
      </React.Fragment>
    );
};

export default App;

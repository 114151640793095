import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading(props) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    function tick() {
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 20);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={(props.results ? 'results ': '')+ 'loader'}>
      <CircularProgress
        variant="determinate"
        value={progress}
        thickness= {props.results ? 2 : 1}
        size={props.results ? 150 : 40}
      />
    </div>
  );
}
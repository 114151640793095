import React, {Component} from 'react';
import WorkOutlineOutlined from '@material-ui/icons/WorkOutlineOutlined';
import WatchLaterOutlined from '@material-ui/icons/WatchLaterOutlined';
import Home from '@material-ui/icons/Home';
import AppContext from '../AppContext';

class ResultsPermit extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {(context) =>   
          <React.Fragment>
            {(context.state.eligibilityResult.length > 0 && context.state.eligibilityResult[0] != null) &&
            <div className="pt-4 row">
              <div className="col-lg col-lg-7">
                <div className="d-flex align-items-center">
                  <WorkOutlineOutlined className="results-permit-briefcase"></WorkOutlineOutlined>
                  <h6 className="ml-2 about-permit-heading">PERMIT TYPE</h6>
                </div>
                <h2 className="mt-3 results-permit-heading-type">{context.state.eligibilityResult[0].name}</h2>
                <p className="mt-3 results-description" dangerouslySetInnerHTML={{__html:context.state.eligibilityResult[0].description}}></p>
              </div>
              <div className="col-lg col-lg-2 ">
                <div className="d-flex align-items-center">
                  <WatchLaterOutlined className="results-permit-clock"></WatchLaterOutlined>
                  <h6 className="ml-2 about-permit-heading">VALIDITY</h6>
                </div>
                {
                  context.state.eligibilityResult[0].validity < 1
                    ? context.state.eligibilityResult[0].validity === 0
                      ? <h2 className="mt-3 results-permit-heading">Indefinite</h2>
                      : <h2 className="mt-3 results-permit-heading">N/A</h2>
                    : <h2 className="mt-3 results-permit-heading">{context.state.eligibilityResult[0].validity} years</h2>
                }
              </div>
              <div className="col-lg col-lg-3 ">
                <div className="d-flex  align-items-center">
                  <Home className="results-permit-home"></Home>
                  <h6 className="ml-2 about-permit-heading">PERMANENT RESIDENCY</h6>
                </div>
                <h2 className="mt-3 results-permit-heading">
                  {
                    context.state.eligibilityResult[0].permanentResidency < 2
                      ? context.state.eligibilityResult[0].permanentResidency === 0
                        ? 'Not Permitted'
                        : 'Permitted'
                      : 'N/A'
                  }
                </h2>
              </div>
            </div>
            }
          </React.Fragment>
        }
      </AppContext.Consumer>
    );
  }
}

export default ResultsPermit;
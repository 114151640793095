import React, {Component} from 'react';
import {authApi} from '../../api';
import AppContext from '../AppContext';
import {format} from 'date-fns';

class AppProvider extends Component {
  state = {
    countries: [],
    destinationCountries: [],
    fieldsOfStudyOptions: [],
    jobLevelOptions: [],
    salaryOptions: [],
    jobPositions: [],
    isSignedIn: false,
    selectedDestinationRegion: null,
    selectedSourceRegion: null,
    selectedCitizenship: null,
    selectedCitizenshipSpecificVisa: null,
    selectedJobPosition: null,
    responses: [],
    requirements: [],
    eligibilityResult: [],
    ineligibleResult: [],
    isAssessmentComplete: false,
    startDates: null,
    isLoadingResults: false,
    isLoadingStartDates: false,
    acceptanceDate: format(new Date(),'yyyy-MM-dd'),
    responsesValid: true,
    startTime: null,
    reportingResults: [],
    gHireId: null,
    isExistingVisaHolder: null,
    existingVisas: null,
    nextNode: null,
    answeredNodes: [],
    selectedExistingVisa: null,
    selectedVisaId: null,
    imageUrl: localStorage.getItem('image'),
    isCompletedExistingVisa: false,
    isLoadingExistingVisas: false,
    isInResultsPage: false,
    totalProcessingTime: null,
    deloitteProcessingTime: null,
    processingTime: null,
    visaProcessingTime: null,
    inCountryProcessingTime: null,
    showStartDate: true,
    citizenshipSpecificVisas: [], // Only applies to the US for now
  }

  componentDidMount() {
    this.api = authApi();
    this.fetchDestinationCountries();
    this.fetchCountries();
    this.fetchJobPositions();
    this.buildCompanyJobLevelDropdown();
    this.fetchReportingResults({
      dateFrom: Date.now() - 14 * 24 * 60 * 60 * 1000,
      dateTo: Date.now() + 24 * 60 * 60 * 1000
    });
  }

  /**
   * Fetch all the countries (used for citizenship)
   */
  async fetchCountries() {
    const response = await this.api.get('/countries');
    if (response) {
      this.setState({countries: response.data});
    }
  }

  /**
   * Fetch the countries which the client has configured for them
   */
  async fetchDestinationCountries() {
    const response = await this.api.get('/destinationCountries');
    if (response) {
      this.setState({destinationCountries: response.data});
    }
  }

  async fetchCitizenshipSpecificWorkPermits() {
    const response = await this.api.get(`/work-permits/country/${this.state.selectedDestinationRegion.country.id }/citizenship-specific`);
    if (response) {
      this.setState({citizenshipSpecificVisas: response.data});
    }
  }

  /**
   * Fetch the client's job positions
   */
  async fetchJobPositions() {
    const response = await this.api.get('/job-positions');
    if (response){
      this.setState({jobPositions: response.data});
    }
  }

  /**
   * Fetch the past reports
   */
  fetchReportingResults = (reportingObject) => {
    this.api.post('/work-permits/assessments/filter', reportingObject)
      .then(response => {
        if(response) {
          this.setState({reportingResults: response.data});
        }
      });
  }

  /**
   * Retrieve the requirements for the selected country
   * @param {string} name The name of the step
   */
  async fetchRequirements(name) {
    if (name === 'selectedDestinationRegion') {
      // Line needs to be updated for company id?
      const response = await this.api.get('/countries/' + this.state.selectedDestinationRegion.country.id + '/regions/'+ this.state.selectedDestinationRegion.region.id + '/requirements');
      if (response) {
        // Retrieve the regular dropdown requirements and the special field of study requirement
        // const requirements = response.data.filter(req => req.type !== 'fieldOfStudy');
        const requirements = response.data;
        // const fieldOfStudyRequirement = response.data.filter(req => req.type === 'fieldOfStudy')[0];

        // Push the field of study question to the end
        // if (fieldOfStudyRequirement) {
        //   requirements.push(fieldOfStudyRequirement);
        // }
        this.setState({
          requirements,
          isLoadingExistingVisas: true
        }, () => this.fetchExistingVisas());

        const emptyResponses = [];

        for (const requirement of response.data) {
          emptyResponses.push({ requirementId: requirement.id, requirementName: requirement.requirementRevisions[0].name, value:{ id: null, value: null }});
        }

        this.setState({ responses: emptyResponses }, () => this.buildFieldOfStudyOptions(this.state.selectedDestinationRegion.country.id));

        if (response.data.find(requirement => requirement.type === 'salaryThreshold')) {
          const salaryRequirement = response.data.find(requirement => requirement.type === 'salaryThreshold');
          const salaryDropdown = await this.buildSalaryDropdown(salaryRequirement.values);
          this.setState({ salaryOptions: salaryDropdown });
        }
      }
    }
  }

  /**
   * Fetch the existing requirements for the selected country
   */
  async fetchExistingVisas() {
    const response = await this.api.get('/countries/' + this.state.selectedDestinationRegion.country.id + '/regions/'+ this.state.selectedDestinationRegion.region.id + '/existing-visas');
    if (response) {
      this.setState({existingVisas: response.data, isLoadingExistingVisas: false}, () => this.fetchCitizenshipSpecificWorkPermits(this.state.selectedDestinationRegion.country.id));
    }
  }

  /**
   * Fetch the next node in the existing visas decision tree
   * @param {object} edge The chosen edge which takes us to the next node
   */
  fetchNextNode = (edge) => {
    let nodes = this.state.answeredNodes;
    nodes.push(this.state.nextNode);
    this.setState({
      answeredNodes: nodes
    }, () => {
      this.api.get('/countries/' + this.state.selectedDestinationRegion.country.id + '/regions/'+ this.state.selectedDestinationRegion.region.id + '/existing-visas/'+ this.state.selectedVisaId+'/select-edge/'+ edge.response.id)
        .then(response => {
          if (response) {
            this.setState({nextNode: response.data});
          }
        });
    });
  }

  /**
   * Fetch the first node in the existing visa
   * @param {object} visa Fetch the first node for the existing visa
   */
  fetchFirstNode = (visa) => {
    this.api.get('/countries/' + this.state.selectedDestinationRegion.country.id + '/regions/'+ this.state.selectedDestinationRegion.region.id + '/existing-visas/'+ visa.response.id)
      .then(response => {
        if (response) {
          this.setState({nextNode: response.data, selectedVisaId: visa.response.id });
        }
      });
  }

  /**
   * Get the next available start dates for the candidate
   * @param {date} date The chosen date to start
   * @param {processingTime} processingTime The time which will be added on to the date
   * @param {number} workPermitId The id of the work permit the candidate is eligible for
   */
  getStartDates = (date, processingTime, workPermitId) => {
    const startDateObject = {
      acceptanceDate: date,
      totalProcessingTime: processingTime
    };

    this.api.post(('work-permits/' + workPermitId + '/recruiter-output/calculate-start-dates'), startDateObject)
      .then(response => {
        if (response) {
          this.setState({startDates: response.data, isLoadingStartDates: false, acceptanceDate: date});
        }
      });
  };

  /**
   * Retrieve the fields of studies for the country and build the dropdown for the assessment
   * @param {number} countryId The id of the country
   */
  buildFieldOfStudyOptions = async () => {
    const fieldOfStudyResponse = await this.api.get('/fields-of-study');

    let tempFieldOfStudyOptions = [];

    if (fieldOfStudyResponse) {
      for (const fieldOfStudyObject of fieldOfStudyResponse.data) {
        tempFieldOfStudyOptions.push({
          label: fieldOfStudyObject.name + ' - ' + fieldOfStudyObject.description,
          value: fieldOfStudyObject.id,
        });
      }
    }

    this.setState({ fieldsOfStudyOptions: tempFieldOfStudyOptions });
  }

  /**
   *  Retrieve and build the dropdown option for job levels
   */ 
  buildCompanyJobLevelDropdown = async () => {
    const jobLevelResponse = await this.api.get('/job-levels');
    let tempJobLevels = [];

    if (jobLevelResponse) {
      for (const jobLevelObject of jobLevelResponse.data) {
        tempJobLevels.push({
          label: jobLevelObject.name,
          companyJobLevelId: jobLevelObject.id,
          type: 'jobLevel',
          value: jobLevelObject, // May need to refactor in order to be in line with how the object looks for updateJobLevel
        });
      }
    }

    this.setState({ jobLevelOptions: tempJobLevels });
  }

  buildSalaryDropdown = async (salaryValues) => {
    let salaryDropdown = [];
    for (const salaryRange of salaryValues) {
      salaryDropdown.push({
        label: salaryRange.value,
        value: salaryRange.min.toString(),
      });
    }
    return salaryDropdown;
  }

  setIsStartDateHidden = (exportStartDateStatus) => {
    this.setState({
      showStartDate: exportStartDateStatus,
    });
  }

  updateState = (name, value) => {
    this.setState({
      [name]: value
    }, () => {
      this.fetchRequirements(name);
    });
  }

  submitResponses = (event) => {
    event.preventDefault();
    let responsesValid = true;

    for (const response of this.state.responses) {
      if (!response.value.value) {
        responsesValid = false;
      }
    }

    if(!this.state.selectedSourceRegion || !this.state.selectedJobPosition){
      responsesValid = false;
    }

    if (!responsesValid) {
      this.setState({
        responsesValid: false,
      });
    } else {
      this.setState({
        isLoadingResults: true,
      });

      const responses = {
        sourceRegion: this.state.selectedSourceRegion.region,
        sourceCountry: this.state.selectedSourceRegion.country,
        selectedCitizenship: this.state.selectedCitizenship,
        selectedCitizenshipSpecificVisa: this.state.selectedCitizenshipSpecificVisa,
        selectedExistingVisa: this.state.selectedExistingVisa,
        jobPosition: this.state.selectedJobPosition,
        responses: this.state.responses,
        assessmentStartTime: this.state.startTime,
        assessmentFinishTime: Date.now(),
        destinationRegion: this.state.selectedDestinationRegion.region,
        destinationCountry: this.state.selectedDestinationRegion.country,
        gHireId: this.state.gHireId
      };

      this.api.post('work-permits/validate-candidate/region/' + this.state.selectedDestinationRegion.region.id, responses)
        .then(response => {
          if (response) {
            this.setState({
              eligibilityResult: response.data.eligibleWorkPermits,
              ineligibleResult: response.data.ineligibleWorkPermits,
              isAssessmentComplete: true,
              isLoadingResults: false,
              isLoadingStartDates: true
            }, () => {
              if (response.data.eligibleWorkPermits.length > 0 && response.data.eligibleWorkPermits[0] != null ) {
                // Only use premium if it has already been configured. - Google will always prioritse premium times.
                // premiumProcessingTime, totalPremiumProcessingTime, visaPremiumProcessingTime
                this.setState({
                  deloitteProcessingTime: response.data.eligibleWorkPermits[0].deloitteProcessingTime
                });

                if (response.data.eligibleWorkPermits[0].totalPremiumProcessingTime > 0) {
                  this.getStartDates(
                    this.state.acceptanceDate, 
                    response.data.eligibleWorkPermits[0].totalPremiumProcessingTime, 
                    response.data.eligibleWorkPermits[0].workPermitId);

                  this.setState({
                    totalProcessingTime: response.data.eligibleWorkPermits[0].totalPremiumProcessingTime,
                    processingTime: response.data.eligibleWorkPermits[0].premiumProcessingTime,
                    visaProcessingTime: response.data.eligibleWorkPermits[0].visaPremiumProcessingTime,
                    inCountryProcessingTime: response.data.eligibleWorkPermits[0].inCountryProcessingTime
                  });
                } else {
                  this.getStartDates(
                    this.state.acceptanceDate, 
                    response.data.eligibleWorkPermits[0].totalProcessingTime, 
                    response.data.eligibleWorkPermits[0].workPermitId);

                  this.setState({
                    totalProcessingTime: response.data.eligibleWorkPermits[0].totalProcessingTime,
                    processingTime: response.data.eligibleWorkPermits[0].processingTime,
                    visaProcessingTime: response.data.eligibleWorkPermits[0].visaProcessingTime,
                    inCountryProcessingTime: response.data.eligibleWorkPermits[0].inCountryProcessingTime
                  });
                }
              } else {
                this.setState({isLoadingStartDates: false});
              }
            });
          }
        });
      window.location.replace('/#/results');
    }
  };

  resetAssessment = () => {
    this.setState({
      selectedDestinationRegion: null,
      selectedSourceRegion: null,
      selectedJobPosition: null,
      selectedCitizenship: null,
      selectedCitizenshipSpecificVisa: null,
      responses: [],
      requirements: [],
      eligibilityResult: [],
      isAssessmentComplete: false,
      startDates: null,
      responsesValid: true,
      isExistingVisaHolder: null,
      nextNode: null,
      selectedExistingVisa: null,
      gHireId: null,
      isCompletedExistingVisa: false,
      startTime: null,
      answeredNodes: [],
      existingVisas: null,
      fieldsOfStudyOptions: [],
      acceptanceDate: format(new Date(),'yyyy-MM-dd'),
    });
  };

  updateSelectedExistingVisa = (event) => {
    this.setState({ selectedExistingVisa: event });
  };

  updateRequirement = (event, index) => {
    let responses = this.state.responses;

    if (event.response) {
      responses[index].value = {
        id: event.response.value.id,
        value: event.response.value.requirementValueRevisions[0].value,
      };
    } else {
      responses[index].value = {
        id: 'salaryThreshold',
        value: event.value,
        label: event.label,
      };
    }

    this.setState({
      responses,
      responsesValid: true
    });
  };

  updateFieldOfStudy = (event, index, requirement) => {
    let { responses } = this.state;

    responses[index].value = {
      id: requirement.id,
      type: requirement.type,
      value: event.value,
      // Use the value in the dropdown to add the name of the field of study, and split so that we only get the title of the field of study.
      title: this.state.fieldsOfStudyOptions.filter(fieldOfStudy => fieldOfStudy.value === event.value)[0].label.split(' - ')[0],
    };

    this.setState({
      responses,
      responsesValid: true,
    });
  }

  updateJobLevel = (event, index, requirement) => {
    let { responses } = this.state;

    responses[index].value = {
      id: requirement.id,
      type: requirement.type,
      value: event.value,
      companyJobLevelId: event.value.id,
    };

    this.setState({
      responses,
      responsesValid: true,
    });
  }

  updateNumericValue = (event, index, requirement) => {
    let responses = this.state.responses;
    responses[index].value = {
      id: null,
      value: event.target.value
    };
    this.setState({
      responses
    });
  };

  updateAnalyticsPath = (pathname) => {
    const gtag = window.gtag;
    gtag('config', process.env.REACT_APP_TRACKING_ID, {
      'page_path': pathname,
    });
  }

  trackAnalyticsEvent = (action,label, category) => {
    const gtag = window.gtag;
    gtag('event', action, {
      'event_label': label,
      'event_category': category
    });
  }


  render() {
    return (
      <AppContext.Provider value={{
        state: this.state,
        updateState: this.updateState,
        fetchRequirements: this.fetchRequirements,
        fetchCitizenshipSpecificWorkPermits: this.fetchCitizenshipSpecificWorkPermits,
        resetAssessment: this.resetAssessment,
        updateSelectedExistingVisa: this.updateSelectedExistingVisa,
        updateNumericValue: this.updateNumericValue,
        updateRequirement: this.updateRequirement,
        updateFieldOfStudy: this.updateFieldOfStudy,
        updateJobLevel: this.updateJobLevel,
        submitResponses: this.submitResponses,
        getStartDates: this.getStartDates,
        fetchReportingResults: this.fetchReportingResults,
        fetchNextNode: this.fetchNextNode,
        fetchFirstNode: this.fetchFirstNode,
        updateAnalyticsPath: this.updateAnalyticsPath,
        trackAnalyticsEvent: this.trackAnalyticsEvent,
        setIsStartDateHidden: this.setIsStartDateHidden,
      }}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export default AppProvider;

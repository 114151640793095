import React, { Component } from 'react';
import AppContext from '../AppContext';
import RequirementQuestion from '../RequirementQuestion';

export default class SelectCitizenship extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countries: [],
      selectedCitizenship: null,
    };
  }

  returnCountryOptions(name, destinationCountries) {
    let options = [{
      label: 'Not disclosed',
      value: 'Not disclosed',
      response: {
        region: {
          id: 0,
          name: 'Not disclosed'
        },
        country: {
          id: 0,
          name: 'Not disclosed'
        }, 
      },
    }];

    for (const country of destinationCountries) {
      for (const region of country.regions) {
        options.push({
          label: country.regions.length > 1 ? (country.name + ' - ' + region.name) : (country.name), 
          value: name, 
          response: {region: region, country: country}
        });
      }
    }

    return options;
  }

  returnDefaultValue(stateValue, options) {
    if (stateValue) {
      const regionOptions = this.returnCountryOptions('selected', options);
      const optionToReturn = regionOptions.find(option => option.label.includes(stateValue));

      return optionToReturn;
    } else {
      return null;
    }
  }

  continueToNext(citizenship, destinationCountryId) {
    if (citizenship === 'Not disclosed' && destinationCountryId === 1) {
      this.props.updateSelectingState('isSelectingCitizenship', false);
      this.props.updateSelectingState('isSelectingCitizenshipSubQuestion', true);
    } else{
      this.props.updateSelectingState('isSelectingCitizenship', false);
      this.props.updateSelectingState('stepIndex', 4); // needs to be updated
      this.props.updateSelectingState('isInRequirementResponseSection', true); // needs to be updated
    }
  }

  render() {
    return (
      <React.Fragment>
        <AppContext.Consumer>
          {(context) => 
            <React.Fragment>
              <RequirementQuestion
                requirementResponse={false}
                options={this.returnCountryOptions('selectedCitizenship', context.state.countries)}
                placeholder="Enter Citizenship"
                question="Has the candidate disclosed their citizenship?"
                description="If the candidate has disclosed their citizenship with you please enter it below, otherwise please proceed without. Citizenship will impact visa eligibility so if you don’t have it now please re-run the assessment when it is available."
                dropdown={true}
                defaultValue={context.state.selectedCitizenship ? (this.returnDefaultValue(context.state.selectedCitizenship.country.name, context.state.countries)) : null}
                id="select-citizenship"
                label="Select Citizenship"
                onChange={ (event) => {
                  context.updateState('selectedCitizenship', event.response);
                }}
                onClickContinue ={() => {this.continueToNext(context.state.selectedCitizenship.country.name, context.state.selectedDestinationRegion.country.id);}}
                onClickBack= {() => {
                  this.props.updateSelectingState('isSelectingCitizenship', false);
                  this.props.updateSelectingState('isSelectingJobPosition', true);
                  this.props.updateSelectingState('stepIndex', 2);
                }}
              />
            </React.Fragment>
          }
        </AppContext.Consumer>
      </React.Fragment>
    );
  }
}
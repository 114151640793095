import React, { useState } from 'react';
import AppContext from '../AppContext';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import HelpOutlineOutlined from '@material-ui/icons/HelpOutlineOutlined';
import LogoutButton from '../LogoutButton';
import Popover from '@material-ui/core/Popover';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Close from '@material-ui/icons/Close';
import HelpAndSupport from '../HelpAndSupport';
import ShowAnnouncement from '../ShowAnnouncements';

const NavigationBar = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const returnHome = () => {
    window.location.assign('#/');
  };

  const navigateToSupport = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <AppContext.Consumer>
      {(context) =>
        <div>
          <AppBar className="navigation-bar" color="inherit">
            <Toolbar className="mb-2">
              <div className="client-logo mt-3 mr-2" aria-label="Asessment home page link" onClick={() => {
                if(window.location.hash === '#/'){
                  window.location.reload();
                } else{
                  context.resetAssessment();
                  returnHome();
                }
              }}>
              </div>
              <div className="ml-auto d-flex">
                <div className="navigation-help mt-3" aria-label="Help and support page link" onClick={() => {navigateToSupport(); context.trackAnalyticsEvent('clicked','Help and feedback modal opened', 'button');}}>
                  <HelpOutlineOutlined className="navigation-help-icon mr-1" />
                  {'Help & Feedback'}
                </div>
                <div className="mt-2 ml-4">
                  {context.state.imageUrl ?
                    <React.Fragment>
                      <img onClick={handleClick} className="navigation-profile-image" src={context.state.imageUrl} alt="Google profile"/>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <div className="logout-button p-4">
                          <LogoutButton />
                        </div>
                      </Popover>
                    </React.Fragment>
                    :
                    <div className="logout-button mt-2">
                      <LogoutButton />
                    </div>
                  }
                </div>
              </div>
            </Toolbar>
            <ShowAnnouncement selectedDestinationCountry={context.state.selectedDestinationRegion && context.state.selectedDestinationRegion.region}/>
          </AppBar>
          <Modal className="help-and-support-modal" aria-labelledby ="help-and-feedback-title" aria-describedby="help-and-feedback-modal" open={modalOpen} onClose={handleModalClose}>
            <Fade in={modalOpen}>
              <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                <div className="position-absolute">
                  <div className="position-absolute d-flex justify-content-end w-100 vc__help-and-support-close-icon" onClick={handleModalClose}>
                    <Close className="vc__close-button"/>
                  </div>
                  <div className="d-flex align-items-center bg-white shadow help-and-support-modal-content">
                    <HelpAndSupport />
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      }
    </AppContext.Consumer>
  );
};

export default NavigationBar;

import React, { Component, Fragment } from 'react';
import AppContext from '../components/AppContext';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ResultsShare from './ResultsShare';

class ResultsSummary extends Component {
  getFieldOfStudyResponse(response, fieldOfStudyOptions) {
    let fieldOfStudyResponse = response.filter(res => res.value.type === 'fieldOfStudy')[0];
    let isSelectedFieldOfStudyFound = fieldOfStudyOptions.filter(fos => fos.value === fieldOfStudyResponse.value.value)[0]; 

    if (isSelectedFieldOfStudyFound) {
      let fieldOfStudyTitle = isSelectedFieldOfStudyFound.label.split(' - ');
      return fieldOfStudyTitle[0];
    }
    return 'N/A';
  }

  render() {
    return (
      <AppContext.Consumer> 
        {(context) => 
          <Fragment>
            {!this.props.isEligible &&
            <p className="mt-1 results-description" dangerouslySetInnerHTML={{__html:context.state.ineligibleResult[this.props.index].workPermit.workPermitRevisions[0].description}}>
            </p>
            }
            {/* Gets the requirement by id. */}
            <div className="d-flex align-items-center">
              <div className="vc__results-summary-heading mt-3 mb-3 vc__summary-heading-req-width">REQUIREMENT</div>
              <div className="vc__results-summary-heading mt-3 mb-3 vc__summary-heading-res-width">RESPONSE</div>
              <div className="vc__results-summary-heading mt-3 mb-3"></div>
            </div>
            <div className="vc__summary-divider"></div>

            {/* TODO: Want to eventually move the mandatory questions into an array so that this can be a loop. */}
            <div className="d-flex align-items-center">
              <div className="vc__results-summary-req mb-3 mt-3">Destination Country</div>
              <div className="vc__results-summary-res mb-3 mt-3">{context.state.selectedDestinationRegion.country.name}</div>
              <CheckIcon className="vc__results-summary-check" />
            </div>
            <div className="vc__summary-divider"></div>

            <div className="d-flex align-items-center">
              <div className="vc__results-summary-req mb-3 mt-3">Source Country</div>
              <div className="vc__results-summary-res mb-3 mt-3">{context.state.selectedSourceRegion.country.name}</div>
              {!this.props.isEligible
                ? context.state.ineligibleResult[this.props.index].requirementsMap.filter(req => req.requirementId === 'Source Country')[0]?.isMatching
                  ? <CheckIcon className="vc__results-summary-check" />
                  : <ClearIcon className="vc__results-summary-x" />
                :  <CheckIcon className="vc__results-summary-check" />
              }
            </div>
            <div className="vc__summary-divider"></div>

            <div className="d-flex align-items-center">
              <div className="vc__results-summary-req mb-3 mt-3">Job Position</div>
              <div className="vc__results-summary-res mb-3 mt-3">{context.state.selectedJobPosition.jobFamily}</div>
              {!this.props.isEligible
                ? context.state.ineligibleResult[this.props.index].requirementsMap.filter(req => req.requirementId === 'Job Code')[0]?.isMatching
                  ? <CheckIcon className="vc__results-summary-check" />
                  : <ClearIcon className="vc__results-summary-x" />
                :  <CheckIcon className="vc__results-summary-check" />
              }
            </div>
            <div className="vc__summary-divider"></div>

            <div className="d-flex align-items-center">
              <div className="vc__results-summary-req mb-3 mt-3">Citizenship</div>
              <div className="vc__results-summary-res mb-3 mt-3">
                {
                  context.state.selectedCitizenshipSpecificVisa
                    ? context.state.selectedCitizenship.country.name  + ' (' + context.state.selectedCitizenshipSpecificVisa.responseValue + ')'
                    : context.state.selectedCitizenship.country.name
                }
              </div>
              {!this.props.isEligible
                ? context.state.ineligibleResult[this.props.index].requirementsMap.filter(req => req.requirementId === 'Citizenship')[0]?.isMatching
                  ? <CheckIcon className="vc__results-summary-check" />
                  : <ClearIcon className="vc__results-summary-x" />
                :  <CheckIcon className="vc__results-summary-check" />
              }
            </div>
            <div className="vc__summary-divider"></div>
            {context.state.requirements.map((requirement, index) => {
              return(
                <Fragment>
                  { (requirement.type !== 'fieldOfStudy' && requirement.type !== 'jobLevel') &&
                    <div>
                      <div className="d-flex align-items-center">
                        <div className="vc__results-summary-req pt-3 pb-3">{requirement.requirementRevisions[0].name}</div>
                        <div className="vc__results-summary-res pt-3 pb-3">
                          { requirement.type === 'salaryThreshold' 
                            ? context.state.responses[index].value.label
                            : context.state.responses[index].value.value
                          }
                        </div>
                        {!this.props.isEligible
                          ? context.state.ineligibleResult[this.props.index].requirementsMap.filter(req => req.requirementId == requirement.id)[0]?.isMatching
                          /* eslint eqeqeq: 0 */
                                ? <CheckIcon className="vc__results-summary-check" />
                                : <ClearIcon className="vc__results-summary-x" />
                          : <CheckIcon className="vc__results-summary-check" />
                        }
                      </div>
                      <div className="vc__summary-divider"></div>
                    </div>
                  }
                </Fragment>
              );
            })}

            {context.state.requirements.filter(requirement => requirement.type === 'fieldOfStudy')[0] &&
              <Fragment>
                <div className="d-flex align-items-center">
                  <div className="vc__results-summary-req mb-3 mt-3">Field of Study</div>
                  <div className="vc__results-summary-res mb-3 mt-3">{this.getFieldOfStudyResponse(context.state.responses, context.state.fieldsOfStudyOptions)}</div>
                  { (this.props.isEligible || !context.state.ineligibleResult[this.props.index].requirementsMap.filter(req => req.requirementId === 'FieldOfStudy').length > 0) 
                    ? <CheckIcon className="vc__results-summary-check" />
                    : context.state.ineligibleResult[this.props.index].requirementsMap.filter(req => req.requirementId === 'FieldOfStudy')[0]?.isMatching
                      ? <CheckIcon className="vc__results-summary-check" />
                      : <ClearIcon className="vc__results-summary-x" />
                  }
                </div>
                <div className="vc__summary-divider"></div>
              </Fragment>
            }

            { context.state.requirements.filter(requirement => requirement.type === 'jobLevel')[0] &&
              <Fragment>
                <div className="d-flex align-items-center">
                  <div className="vc__results-summary-req mb-3 mt-3">Job Level</div>
                  <div className="vc__results-summary-res mb-3 mt-3">{context.state.responses.filter(res => res.value.type === 'jobLevel')[0].value.value.name}</div>
                  { (this.props.isEligible || !context.state.ineligibleResult[this.props.index].requirementsMap.filter(req => req.requirementId === 'jobLevel').length > 0)
                    ? <CheckIcon className="vc__results-summary-check" />
                    : context.state.ineligibleResult[this.props.index].requirementsMap.filter(req => req.requirementId === 'jobLevel')[0]?.isMatching
                      ? <CheckIcon className="vc__results-summary-check" />
                      : <ClearIcon className="vc__results-summary-x" />
                  }
                </div>
                <div className="vc__summary-divider"></div>
              </Fragment>
            }

            <div className="pt-5 vc__ghire-copy-ineligible-width">
              {!this.props.isEligible &&
                <ResultsShare 
                  ineligiblePermit={context.state.ineligibleResult[this.props.index]} 
                  requirementsMap={context.state.ineligibleResult[this.props.index].requirementsMap}
                />
              }
            </div>
          </Fragment>
        }
      </ AppContext.Consumer>
    );
  }
}

export default ResultsSummary;
import React, { Component } from 'react';
import AppContext from '../AppContext';
import RequirementQuestion from '../RequirementQuestion';

class GHireId extends Component {

  returnHome = () => {
    window.location.replace('/#/');
  }
  //Check for character limitations
  limitReached = false;
  render() {
    return (
      <AppContext.Consumer>
        {(context) => {
          if (context.state.gHireId) this.limitReached = context.state.gHireId.length >= window.$textFieldMaxLength;
          return(
              <RequirementQuestion
                limitReachMessage={ this.limitReached ? 'Maximum character limit reached for this field.' : ''}
                requirementResponse={false}
                placeholder="Enter gHire App ID"
                description="Please enter the gHire Application ID for reference on this assessment. If no gHire App ID is available, please click continue and proceed without. gHire App IDs will be used by the Google Immigration team to improve VisaCheck assessment accuracy."
                question="Is this candidate associated to an application in gHire?"
                dropdown={false}
                text = {true}
                id={'enter-ghire-id'}
                defaultValue={context.state.gHireId}
                onClickContinue ={() => {
                  this.props.updateSelectingState('stepIndex', (parseInt(this.props.stepIndex)+1));
                  this.props.updateSelectingState('requirementIndex', (parseInt(this.props.requirementIndex)+1));
                }}
                onClickBack ={() => {
                  this.props.updateSelectingState('stepIndex', (parseInt(this.props.stepIndex)-1));
                  this.props.updateSelectingState('requirementIndex', (parseInt(this.props.requirementIndex)-1));
                }}
                label="please-enter-ghire-id-if-applicable"
                onChange={(event) => { context.updateState('gHireId', event.target.value); this.limitReached = (event.target.value.length >= event.target.maxLength);}}
              />
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default GHireId;

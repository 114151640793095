import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '0rem',
    overflowX: 'auto',
    boxShadow: 'none',
  },
  table: {
    minWidth: 650,
  },
}));

export default function ReportingTable(props) {
  const classes = useStyles();

  return ( 
    <div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Country</TableCell>
              <TableCell align="center">Volume</TableCell>
              <TableCell align="center">Eligible</TableCell>
              <TableCell align="center">Ineligible</TableCell>
              <TableCell align="center">Minutes/Assessment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.reportingResults.map(country => (
              <TableRow key={country.name}>
                <TableCell align="left">{country.destination_country}</TableCell>
                <TableCell align="center">{country.volume}</TableCell>
                <TableCell align="center">{country.eligible}</TableCell>
                <TableCell align="center">{country.not_eligible}</TableCell>
                <TableCell align="center">{Number(country.average_time/60000).toFixed(1)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <div onClick={()=> window.location.assign('/#/')} className="mb-4 d-flex justify-content-start help-and-support-return-home">
        Back to home
      </div>
    </div>
  );
}
import React from 'react';
import TimerOutlined from '@material-ui/icons/TimerOutlined';
import AppContext from '../AppContext';

// Update processing times here: premiumProcessingTime, totalPremiumProcessingTime, visaPremiumProcessingTime
export default function ResultsProcessingTime(props) {
  return (
    <AppContext.Consumer>
      {(context) =>   
        <div>
          {(context.state.eligibilityResult.length > 0 && context.state.eligibilityResult[0] != null && context.state.totalProcessingTime !== null) &&
            <div className="results-processing-time-container">
              <div className="d-flex estimated-processing-time-container  align-items-center">
                <TimerOutlined className="results-processing-timer"></TimerOutlined>
                <h6 className="pl-2 about-permit-heading">ESTIMATED PROCESSING TIME</h6>
              </div>
              <p className="mt-3 results-description">This indicative processing time is from the point of offer acceptance date.</p>
              <p className="mt-2 results-total-processing">{context.state.totalProcessingTime} weeks</p>

              <div className="d-flex results-visa-processing-bar">
                {context.state.deloitteProcessingTime > 0 &&
                <div style= {{width: (Math.round((context.state.deloitteProcessingTime * 100) / context.state.totalProcessingTime).toFixed(2))+'%', minWidth:'15%'}}>
                  <div className="results-document-bar"></div>
                  <h6 className="pt-1 results-processing-weeks">{context.state.deloitteProcessingTime} {context.state.deloitteProcessingTime > 1 ? ' weeks' : ' week'}</h6>
                  <p className="results-about-processing-weeks">DOCUMENT + APPLICATION PREPARATION</p>
                </div>
                }
                {context.state.processingTime > 0 &&
                <div style= {{width: (Math.round((context.state.processingTime * 100) / context.state.totalProcessingTime).toFixed(2))+'%', minWidth:'15%'}}>
                  <div className="results-government-bar"></div>
                  <h6 className="pt-1 results-processing-weeks">{context.state.processingTime} {context.state.processingTime > 1 ? ' weeks' : ' week'}</h6>
                  <p className="results-about-processing-weeks">WORK PERMIT PROCESSING</p>
                </div>
                }
                {context.state.visaProcessingTime > 0 &&
                <div style= {{width: (Math.round((context.state.visaProcessingTime * 100) / context.state.totalProcessingTime).toFixed(2)) + '%', minWidth:'15%'}}>
                  <div className="results-visa-bar"></div>
                  <h6 className="pt-1 results-processing-weeks">{context.state.visaProcessingTime} {context.state.visaProcessingTime > 1 ? ' weeks' : ' week'}</h6>
                  <p className="results-about-processing-weeks">VISA PROCESSING</p>
                </div>
                }
              {context.state.inCountryProcessingTime > 0 &&
               <div style= {{width: (Math.round((context.state.inCountryProcessingTime * 100) / context.state.totalProcessingTime).toFixed(2))+'%', minWidth:'15%'}}>
                 <div className="results-in-country-time-bar"></div>
                 <h6 className="pt-1 results-processing-weeks">{context.state.inCountryProcessingTime} {context.state.inCountryProcessingTime > 1 ? ' weeks' : ' week'}</h6>
                 <p className="results-about-processing-weeks">PROCESSING TIME IN DESTINATION COUNTRY TO START WORK</p>
               </div>
               }
              </div>
            </ div>
          }
        </div>
      }
    </AppContext.Consumer>
  );
}

export const UserReducer = (state, action) => {
  switch (action.type) {
  case 'LOGIN_SUCCESS':
    return {
      ...state,
      isLoggedIn: action.isLoggedIn,
      isForbidden: action.isForbidden,
    };
  case 'LOGIN_FAIL':
    return {
      ...state,
      isLoggedIn: action.isLoggedIn,
      isForbidden: action.isForbidden,
    };
  case 'LOGIN_FORBIDDEN':
    return {
      ...state,
      isLoggedIn: action.isLoggedIn,
      isForbidden: action.isForbidden,
    };
  case 'SESSION_EXPIRED':
    return {
      ...state,
      isLoggedIn: action.isLoggedIn,
      isForbidden: action.isForbidden,
    };
  case 'LOGOUT':
    return {
      ...state,
      isLoggedIn: false,
      isForbidden: false,
    };
  default:
    return state;
  }
};


import React, { Component } from 'react';
import AttachFileOutlined from '@material-ui/icons/AttachFileOutlined';
import InsertDriveFile from '@material-ui/icons/InsertDriveFile';
import AppContext from '../AppContext';

class ResultsAttachments extends Component {
  
  render() {
    return (
      <AppContext.Consumer>
        {(context) => 
          <React.Fragment>
            {(context.state.eligibilityResult.length > 0 && context.state.eligibilityResult[0] != null) &&
          <React.Fragment>
            {context.state.eligibilityResult[0].recruiterOutput.documents.length > 0 &&
            <React.Fragment>
              <div className="mt-5 d-flex">
                <AttachFileOutlined className="mt-2 results-attach-file"></AttachFileOutlined>
                <h6 className="mt-2 ml-2 about-permit-heading">ATTACHMENTS</h6>
              </div>
              <div className="results-attachments-container d-flex">
                {context.state.eligibilityResult[0].recruiterOutput.documents.map((attachment,index) => (
                  <a href={attachment} className="results-attachment-link">
                    <InsertDriveFile className="results-attachment"></InsertDriveFile>
                    <p className="results-attachment-name">{attachment.substring(attachment.lastIndexOf('/') + 1, attachment.lastIndexOf('.pdf'))}</p>
                  </a>
                ))}
              </div>
            </React.Fragment>
            }
          </React.Fragment>
            }
          </React.Fragment>
        }
      </AppContext.Consumer>
    );
  }
}

export default ResultsAttachments;
import React from 'react';
import CalendarTodayOutlined from '@material-ui/icons/CalendarTodayOutlined';
import {KeyboardDatePicker,MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AppContext from '../AppContext';
import Loading from '../Loading';
import {Slide} from '@material-ui/core';
import {format} from 'date-fns';

export default function ResultsStartDate() {
    // Set media print to toggle which field to hide.
    let hideInPdf = `@media print {
                                .hide-start-date {
                                display: none
                              }`;

  function returnMonth(month) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'];
    return monthNames[month];
  }
  
  return (
    <AppContext.Consumer>
      {(context) => 
        <React.Fragment>
          <style>{hideInPdf}</style>
          {(context.state.eligibilityResult.length > 0 && context.state.eligibilityResult[0] != null) && context.state.startDates &&
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {Array.isArray(context.state.startDates.startDates) && 
                <React.Fragment>
                  {context.state.startDates.startDates.length > 1 &&
                <div className={'' + (context.state.showStartDate ? '' : ' hide-start-date')}>
                  <div className={'d-flex results-start-date-container pb-2  align-items-center'}>
                    <CalendarTodayOutlined className="results-start-date-calendar"></CalendarTodayOutlined>
                    <h6 id="start-dates" aria-describedby="start-dates-info" className="pl-2 about-permit-heading">RECOMMENDED START DATES</h6>
                  </div>
                  {context.state.isLoadingStartDates 
                  ? 
                    <div className="results-start-date-loading d-flex justify-content-center">
                      <Loading results={false}></Loading>
                    </div>
                    :
                    <div className="vc_results-start-date-section d-flex">
                    <Slide timeout={{enter:300}} direction="right" in={true} mountOnEnter unmountOnExit>
                      <div className="results-indicative-start-date-container">
                        <div className="results-start-date-widget-container">
                          <hr className="results-start-date-widget-row"></hr>
                          <div className="results-start-date-widget-date">
                            <div className="results-start-date-widget-circle"></div>
                            <div aria-describedby="start-dates-info" aria-labelledby="start-dates recommended" className="results-start-date-widget-day">{new Date(context.state.startDates.startDates[0].date).getDate()}</div>
                            <div aria-describedby="start-dates-info" aria-labelledby="start-dates recommended" className="results-start-date-widget-month">{returnMonth(new Date(context.state.startDates.startDates[0].date).getMonth())}</div>
                            <div id="recommended" className="results-start-date-widget-recommended">RECOMMENDED</div>
                          </div>
                          <div className="results-start-date-widget-date next">
                            <div className="results-start-date-widget-circle"></div>
                            <div aria-describedby="start-dates-info" aria-labelledby="start-dates" className="results-start-date-widget-day">{new Date(context.state.startDates.startDates[1].date).getDate()}</div>
                            <div aria-describedby="start-dates-info" aria-labelledby="start-dates" className="results-start-date-widget-month">{returnMonth(new Date(context.state.startDates.startDates[1].date).getMonth())}</div>
                          </div>
                        </div>
                      </div> 
                    </Slide>
                    <div>
                      <p id="start-dates-info" className="results-description  mt-2">These are the earliest possible start dates based on immigration processing timelines and destination country orientation dates. 
                        They do not include any time for factors outside the immigration process that may impact a candidate's start date (e.g. notice period or international relocation). 
                        It assumes an offer acceptance date of <b> {context.state.acceptanceDate.toString().split('-')[2] + ' ' + (returnMonth(parseInt(context.state.acceptanceDate.split('-')[1])-1)) + ' ' + context.state.acceptanceDate.split('-')[0]}</b></p> 
                        <div className="hide-start-date">
                        <KeyboardDatePicker
                          className="vc__results-start-date-button"
                          value={context.state.acceptanceDate}
                          onChange={(date) => {context.updateState('isLoadingStartDates', true); context.getStartDates(format(date,'yyyy-MM-dd'), context.state.eligibilityResult[0].totalPremiumProcessingTime > 0 ? context.state.eligibilityResult[0].totalPremiumProcessingTime : context.state.eligibilityResult[0].totalProcessingTime, context.state.eligibilityResult[0].workPermitId); context.trackAnalyticsEvent('clicked','Changed offer accept date', 'button');}}
                          minDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                          format="yyyy-mm-dd"
                          disablePast={true}
                          keyboardIcon={<span className="btn vc__white-button">CHANGE OFFER ACCEPT DATE</span>}
                          maxDate={context.state.startDates.latestAcceptenceDate.split('T')[0]}
                          minDateMessage={null}
                        />
                        </div>
                    </div>
                    </div>
                  }
                </div>
                  }
                </React.Fragment>
                }
              </MuiPickersUtilsProvider>
          }
        </React.Fragment>
      }
    </AppContext.Consumer>
  );
}







import React, { useState } from 'react';
import AppContext from '../components/AppContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

const StartDateSwitch = withStyles({
  root : {
    left: -12,
  },
  switchBase: {
    color: blue[300],
    '&$checked': {
      color: '#4688f1',
    },
    '&$checked + $track': {
      backgroundColor: '#4688f1',
    },
  },
  checked: {},
  track: {},
})(Switch);

const ResultsCandidateShare = ({ isStartDateShown, toggleStartDateFromPdf}) => {
  const [startDateToggle, setStartDateToggle] = useState(true);

  const handleChange = (event) => {
    toggleStartDateFromPdf(!startDateToggle);
    setStartDateToggle(!startDateToggle);
  };

  return(
    <AppContext.Consumer>
    {(context) => 
    <div>

    <div className="vc__candidate-share-container">
      <div className="mb-4">
        <h2 className="mt-4 results-permit-heading-type">Share with candidate</h2>
        Assessment result (excluding the application summary) can be saved as a .PDF file and shared with the candidate. 
        Use the toggle switch below to show or hide the recommended start dates.
      </div>

      <div>
          <FormControlLabel
              value="end"
              control={<StartDateSwitch checked={startDateToggle} onChange={handleChange} color="primary" />}
              labelPlacement="end"
              label="Show recommended start dates"
              className="vc__start-date-switch"
            />
      </div>

      <button 
        className="btn vc__white-button mt-4 mr-4" 
        onClick={() => { window.print(); context.trackAnalyticsEvent('clicked', 'Print assessment results', 'button'); }}>
          SAVE A PDF
      </button>

    </div>
    </div>

    }
    </AppContext.Consumer>
  );
};
export default ResultsCandidateShare;

import React, { Component } from 'react';
import AppContext from '../AppContext';
import GHireId from '../GHireId';
import RequirementQuestion from '../RequirementQuestion';
import Summary from '../Summary';

class RequirementResponses extends Component {
  returnRequirementOptions(index, requirement, salaryOptions) {
    let options = [];

    if (requirement.type === 'salaryThreshold') {
      options = salaryOptions;
    } else {
      for (const value of requirement.values){
        if (value.requirementValueRevisions[0].value !== 'Not Required') { // TODO: add the assignment/rotation filter here
          options.push({
            label: value.requirementValueRevisions[0].value,
            value: 'requirement' + value.id,
            response: { index: index, requirement: requirement, value: value },
          });
        }
      }
    }
    return options;
  }

  returnSelectedOption(responseValue, stateRequirementValue, index) {
    let selectedOption = null;
    let selectedValue = stateRequirementValue.values.find(value => value.id === responseValue.id);

    if (selectedValue) {
      selectedOption = {
        label: selectedValue.requirementValueRevisions[0].value,
        value: 'requirement' + selectedValue.id,
        response: { index: index, requirement: stateRequirementValue, value: selectedValue },
      };
    } else {
      selectedOption = {
        label: responseValue.label,
        value: 'requirement' + responseValue.value,
      };
    }
    return selectedOption;
  }

  returnDefaultValue(response, stateRequirementValue, index) {
    if (stateRequirementValue.type !== 'numeric') {
      if (response.value.value) {
        return this.returnSelectedOption(response.value, stateRequirementValue, index);
      }
    } else {
      if (response.value.value) {
        return response.value.value;
      }
    }
  }

  returnSelectedFieldOfStudy(response, fieldsOfStudy) {
    let isSelectedFieldOfStudyFound = fieldsOfStudy.filter(fos => fos.value === response.value.value)[0];

    if (isSelectedFieldOfStudyFound) {
      return {
        label: isSelectedFieldOfStudyFound.label,
        response: {
          id: response.value.id,
          type: response.value.type,
          value: response.value.value,
        },
        value: isSelectedFieldOfStudyFound.value,
      };
    }
  }

  returnSelectedJobLevel(response, jobLevel) {
    let isSelectedJobLevelFound = jobLevel.filter(level => level.value === response.value.value)[0];

    if (isSelectedJobLevelFound) {
      return {
        label: isSelectedJobLevelFound.label,
        response: {
          id: response.value.id,
          type: response.value.type,
          value: response.value.value,
        },
        value: isSelectedJobLevelFound.value,
      };
    }
  }

  returnHome = () => {
    window.location.replace('/#/');
  }

  render() {
    return (
      <AppContext.Consumer>
        {
          (context) => {
            return (
              <React.Fragment>
                {
                  context.state.requirements.length > 0 && context.state.requirements.length > this.props.requirementIndex &&
                  <React.Fragment>
                    {
                      context.state.requirements[this.props.requirementIndex].type !== 'fieldOfStudy' &&
                      context.state.requirements[this.props.requirementIndex].type !== 'jobLevel' &&
                      context.state.requirements.map((requirement, index) => {
                        return(
                          index === this.props.requirementIndex &&
                          <div key = {index}>
                            <RequirementQuestion
                              requirementResponse={true}
                              placeholder={'Enter ' + requirement.requirementRevisions[0].name}
                              options={this.returnRequirementOptions(this.props.requirementIndex, requirement, context.state.salaryOptions)}
                              description={
                                requirement.requirementRevisionsCompany[0]?.description || 
                                requirement.requirementRevisions[0].description
                              }
                              question={
                                requirement.requirementRevisionsCompany[0]?.question || 
                                requirement.requirementRevisions[0].question
                              }
                              prefix={requirement.requirementRevisions[0].prefix}
                              dropdown={requirement.type.includes('Select') || requirement.type.includes('salary') || requirement.type.includes('JobPosition')}
                              defaultValue={this.returnDefaultValue(context.state.responses[this.props.requirementIndex],requirement, this.props.requirementIndex)}
                              id={'enter-'+ requirement.requirementRevisions[0].name}
                              type={requirement.type}
                              label={'Please enter or select '+ requirement.name}
                              onClickContinue ={() => {
                                this.props.updateSelectingState('requirementIndex', (parseInt(this.props.requirementIndex) + 1));
                                this.props.updateSelectingState('stepIndex', (parseInt(this.props.stepIndex) + 1));
                              }}
                              onClickBack ={()=>{
                                if (this.props.requirementIndex === 0) {
                                  this.props.updateSelectingState('isInRequirementResponseSection', false);
                                  this.props.updateSelectingState('stepIndex', 3);
                                  this.props.updateSelectingState('isSelectingCitizenship', true);
                                } else {
                                  this.props.updateSelectingState('stepIndex', (parseInt(this.props.stepIndex) - 1));
                                  this.props.updateSelectingState('requirementIndex', (parseInt(this.props.requirementIndex) - 1));
                                }
                              }}
                              onChange= {event => context.updateRequirement(event, this.props.requirementIndex)}
                            />
                          </div>
                        );
                      })
                    }
                    { // Job Levels
                      context.state.requirements[this.props.requirementIndex].type === 'jobLevel' &&
                      context.state.requirements.map((requirement, index) => {
                        return(
                          index === this.props.requirementIndex &&
                          <div key = {index}>
                            <RequirementQuestion
                              requirementResponse={true}
                              placeholder={'Enter ' + requirement.requirementRevisions[0].name}
                              options={context.state.jobLevelOptions}
                              description={requirement.requirementRevisions[0].description}
                              question={requirement.requirementRevisions[0].question}
                              dropdown={true}
                              type={requirement.type}
                              defaultValue={this.returnSelectedJobLevel(context.state.responses[this.props.requirementIndex], context.state.jobLevelOptions)}
                              id={'enter-'+ requirement.requirementRevisions[0].name}
                              label={'Please enter or select '+ requirement.name}
                              onClickContinue ={() => {
                                this.props.updateSelectingState('requirementIndex', (parseInt(this.props.requirementIndex) + 1));
                                this.props.updateSelectingState('stepIndex', (parseInt(this.props.stepIndex) + 1));
                              }}
                              onClickBack ={()=>{
                                if(this.props.requirementIndex === 0) {
                                  this.props.updateSelectingState('isInRequirementResponseSection', false);
                                  this.props.updateSelectingState('stepIndex', 3);
                                  this.props.updateSelectingState('isSelectingCitizenship', true);
                                } else {
                                  this.props.updateSelectingState('stepIndex', (parseInt(this.props.stepIndex) - 1));
                                  this.props.updateSelectingState('requirementIndex', (parseInt(this.props.requirementIndex) - 1));
                                }
                              }}
                              onChange={event => context.updateJobLevel(event, this.props.requirementIndex, requirement)}
                            />
                          </div>
                        );
                      })
                    }
                    { // Field of Study
                      context.state.requirements[this.props.requirementIndex].type === 'fieldOfStudy' &&
                      context.state.requirements.map((requirement, index) => {
                        return(
                          index === this.props.requirementIndex &&
                          <div key = {index}>
                            <RequirementQuestion
                              requirementResponse={true}
                              placeholder={'Enter ' + requirement.requirementRevisions[0].name}
                              options={context.state.fieldsOfStudyOptions}
                              description={requirement.requirementRevisions[0].description}
                              question={requirement.requirementRevisions[0].question}
                              dropdown={true}
                              type={requirement.type}
                              defaultValue={this.returnSelectedFieldOfStudy(context.state.responses[this.props.requirementIndex], context.state.fieldsOfStudyOptions)}
                              id={'enter-'+ requirement.requirementRevisions[0].name}
                              label={'Please enter or select '+ requirement.name}
                              onClickContinue ={() => {
                                this.props.updateSelectingState('requirementIndex', (parseInt(this.props.requirementIndex) + 1));
                                this.props.updateSelectingState('stepIndex', (parseInt(this.props.stepIndex) + 1));
                              }}
                              onClickBack ={()=>{
                                if(this.props.requirementIndex === 0) {
                                  this.props.updateSelectingState('isInRequirementResponseSection', false);
                                  this.props.updateSelectingState('stepIndex', 3);
                                  this.props.updateSelectingState('isSelectingCitizenship', true);
                                } else {
                                  this.props.updateSelectingState('stepIndex', (parseInt(this.props.stepIndex) - 1));
                                  this.props.updateSelectingState('requirementIndex', (parseInt(this.props.requirementIndex) - 1));
                                }
                              }}
                              onChange={event => context.updateFieldOfStudy(event, this.props.requirementIndex, requirement)}
                            />
                          </div>
                        );
                      })
                    }
                  </React.Fragment>
                }
                {
                  context.state.requirements.length !== 0 && 
                  context.state.requirements.length === this.props.requirementIndex &&
                  <GHireId
                    requirementIndex={this.props.requirementIndex}
                    stepIndex={this.props.stepIndex}
                    updateSelectingState={this.props.updateSelectingState}
                  />
                }
                {
                  context.state.requirements.length === 0 &&
                  <React.Fragment>
                    <div className="d-flex justify-content-center container flex-column">
                      <h1 className="requirement-summary pt-5 w-75">Sorry there are no requirements for the selected destination country</h1>
                      <button className="requirement-question-button mt-4 w-25" onClick={() => {context.resetAssessment(); this.returnHome();}}>Return to Assessment Home</button>
                    </div>
                  </React.Fragment>
                }
                {
                  context.state.requirements.length < this.props.requirementIndex && <Summary index={this.props.index} />
                }
              </React.Fragment>
            );
          }
        }
      </AppContext.Consumer>
    );
  }
}

export default RequirementResponses;

import React, { Component } from 'react';
import ExistingVisaQuestion from '../ExistingVisaQuestion';

class ExistingVisaNodes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayNode: false,
      value: null
    };
  }

  componentDidMount() {
    this.checkNodeType();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.node.id !== this.props.node.id) {
      this.checkNodeType();
    }
  }

  checkNodeType = () => {
    if (Array.isArray(this.props.node)) {
      this.props.updateState('isContinuingAssessment', true);
      this.props.updateContextState('isCompletedExistingVisa', true);
      this.props.updateState('isInExistingVisaDecisionTree', false);
      this.props.updateState('isInSelectingCurrentVisa', false);
    } else {
      if (this.props.node.type === 'CONTINUE_TO_ASSESSMENT_NODE') {
        this.props.updateState('isContinuingAssessment', true);
        this.props.updateContextState('isCompletedExistingVisa', true);
        this.props.updateState('isInExistingVisaDecisionTree', false);
        this.props.updateState('isInSelectingCurrentVisa', false);
      } else if (this.props.node.type === 'IS_ELIGIBLE_NODE') {
        this.props.updateState('visaResult', true);
        this.props.updateContextState('isCompletedExistingVisa', true);
      } else if (this.props.node.type === 'END_NODE') {
        this.props.updateState('visaResult', false);
        this.props.updateState('endNode', this.props.node);
        this.props.updateContextState('isCompletedExistingVisa', true);
      } else {
        this.setState({displayNode: true});
      }
    }
  }

  returnNodeEdgeOptions(stateEdges) {
    let options =[];
    if(stateEdges) {
      for (const edge of stateEdges) {
        options.push({
          label: edge.text,
          value: edge,
          response: edge
        });
      }
    }

    return options;
  }

  render() {
    return (
      this.state.displayNode &&
      <ExistingVisaQuestion
        requirementResponse={false}
        options={this.returnNodeEdgeOptions(this.props.node.childEdges)}
        question={this.props.node.text}
        description={this.props.node.subtext}
        id={`existing-visa-question-${this.props.node.id}`}
        label={`Existing visa question number ${this.props.node.id}`}
        disableContinue={this.props.valueSelected === null}
        onChange={(event) => {
          this.props.updateState('valueSelected', event);
        }}
        onClickContinue ={() => {
          this.props.fetchNextNode(this.props.valueSelected);
          this.props.updateState('valueSelected', null);
        }}
        onClickBack={() => {
          if(this.props.answeredNodes.length === 0){
            this.props.updateState('isInExistingVisaDecisionTree', false);
            this.props.updateState('isInSelectingCurrentVisa', true);
            this.props.updateContextState('nextNode', null);
          } else {
            let finishedNodes= this.props.answeredNodes;
            let newNode = finishedNodes.pop();
            this.props.updateContextState('answeredNodes', finishedNodes);
            this.props.updateContextState('nextNode', newNode);
          }
        }}
      />
    );
  }
}

export default ExistingVisaNodes;

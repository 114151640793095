import React, { useEffect } from 'react';
import Fade from '@material-ui/core/Fade';
import { logout } from '../../services/AuthenticationService';

const SessionExpired = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Don't allow the expired page to be visited by manually typing in the url
    if (localStorage.getItem('image')) {
      window.location.replace('/#/');
    } else {
      setTimeout(async () => {
        await logout();
      }, 2000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <React.Fragment>
      <div className="d-flex justify-content-center align-items-center session-expired-message-container">
        <Fade in={true} timeout={500}>
          <div className="session-expired-message d-flex justify-content-center align-items-center flex-column">
            <h3>Your session has expired</h3>
            <h5 className="mt-3">You will be redirected to log-in page</h5>
          </div>
        </Fade>
      </div>
      <div className="session-expired-background"></div>
    </React.Fragment>
  );
};

export default SessionExpired;

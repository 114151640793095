import React, { Component } from 'react';
import AppContext from '../AppContext';
import RequirementQuestion from '../RequirementQuestion';

class SelectSourceCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      selectedCountry: null,
    };
  }

  returnCountryOptions(name, destinationCountries){
    let options =[];
    for(const country of destinationCountries){
      for(const region of country.regions){
        options.push({
          label: country.regions.length > 1 ? (country.name + ' - ' + region.name) : (country.name), 
          value: name, 
          response: {region: region, country: country}});
      }
    }
    return options;
  }

  returnDefaultValue(stateValue, options) {
    if(stateValue){
      const regionOptions = this.returnCountryOptions('selectedSourceRegion', options);
      const optionToReturn = regionOptions.find(option => option.label.includes(stateValue));
      return optionToReturn;
    } else {
      return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        <AppContext.Consumer>
          {(context) => 
            <React.Fragment>
              <RequirementQuestion
                requirementResponse={false}
                options={this.returnCountryOptions('selectedSourceRegion', context.state.countries)}
                placeholder={'Select Country'}
                question="Where is the candidate currently based?"
                description="Please select the candidate's current location."
                dropdown={true}
                defaultValue={context.state.selectedSourceRegion ? (this.returnDefaultValue(context.state.selectedSourceRegion.country.name, context.state.countries)) : null}
                id="select-source-country"
                label="Select source country"
                onClickContinue ={() => {
                  this.props.updateSelectingState('isSelectingSource', false);
                  this.props.updateSelectingState('stepIndex', 2);
                  this.props.updateSelectingState('isSelectingJobPosition', true);
                }}
              />
            </React.Fragment>
          }
        </AppContext.Consumer>
      </React.Fragment>
    );
  }
}

export default SelectSourceCountry;

import React from 'react';
import AppContext from '../components/AppContext';
import CopyText from '../components/CopyText';
import {format} from 'date-fns';

const ResultsGhireCopy = ({ ineligiblePermit, requirementsMap }) => {
  const getFieldOfStudyResponse = (response, fieldOfStudyOptions) => {
    let fieldOfStudyResponse = response.filter(res => res.value.type === 'fieldOfStudy')[0];
    let isSelectedFieldOfStudyFound = fieldOfStudyOptions.filter(fos => fos.value === fieldOfStudyResponse.value.value)[0]; 

    if (isSelectedFieldOfStudyFound) {
      let fieldOfStudyTitle = isSelectedFieldOfStudyFound.label.split(' - ');
      return fieldOfStudyTitle[0];
    }
    return 'N/A';
  };

  return(
    <AppContext.Consumer>
    {(context) => 
    <div>
    
    <div className="vc__ghire-copy-container">
      <div className="mb-4">
        <h2 className="mt-4 results-permit-heading-type">Copy to gHire</h2>
        {context.state.eligibilityResult.length > 0 
          ? <p>Assessment result (excluding the additional notes and documents) can be copied and pasted into gHire.</p>
          : <p>The summary details above can be copied and pasted into gHire.</p>
        }
      </div>

      <CopyText 
        trackAnalyticsEvent={context.trackAnalyticsEvent}
        eventName="Copy processing time to clipboard"
        textToCopy={
          'VISACHECK ASSESSMENT \n\n\n' +
          ( context.state.eligibilityResult.length > 0
            ? 'Result: Candidate is eligible\n\n\n' +
            'Permit Match: ' + context.state.eligibilityResult[0].name + '\n\n\n' +
            (context.state.startDates ? (
              'Indicative Start Date: ' + format(new Date(context.state.startDates.startDates[0].date),'dd MMMM yyyy') + '\n\n\n') : ''
            ) +
            'Processing Times - Total: ' + context.state.totalProcessingTime + (context.state.totalProcessingTime === 1 ? ' week' : ' weeks') +
              ' (Documents + Application: ' + context.state.deloitteProcessingTime + (context.state.deloitteProcessingTime === 1 ? ' week' : ' weeks') +
              ', Work Permit: ' + context.state.processingTime + (context.state.processingTime === 1 ?  ' week' : ' weeks') +
              ', Visa: ' + context.state.visaProcessingTime + (context.state.visaProcessingTime === 1 ?  ' week)\n\n\n' : ' weeks)\n\n\n') +
              'Based on offer acceptance date of ' + format(new Date(context.state.acceptanceDate),'dd MMMM yyyy')

            : 'Result: Candidate is ineligible\n\n\n' +
            'Closest Permit Match: ' + ineligiblePermit.workPermit.workPermitRevisions[0].name + '\n\n\n' +
            ineligiblePermit.workPermit.workPermitRevisions[0].description
          )  +
          '\n\n\nApplication Summary\n\n' +
          'Destination Country: ' + context.state.selectedDestinationRegion.country.name + (requirementsMap ? ' (Y)' : '') +
          '\n\nSource Country: ' + context.state.selectedSourceRegion.country.name + 
            (requirementsMap 
              ? requirementsMap.filter(req => req.requirementId === 'Source Country')[0]?.isMatching ? ' (Y)' : ' (N)'
              : '') +
          '\n\nJob Position: ' + context.state.selectedJobPosition.jobFamily + 
            (requirementsMap 
              ? requirementsMap.filter(req => req.requirementId === 'Job Code')[0]?.isMatching ? ' (Y)' : ' (N)'
              : '') +
          '\n\nCitizenship: ' + context.state.selectedCitizenship.country.name + 
            (context.state.selectedCitizenshipSpecificVisa
              ? ' (' + context.state.selectedCitizenshipSpecificVisa.responseValue + ')' 
              : '')  +
            (requirementsMap 
              ? requirementsMap.filter(req => req.requirementId === 'Citizenship')[0]?.isMatching ? ' (Y)' : ' (N)'
              : '') +

          context.state.requirements.map((requirement, index) => {
            return(
                (requirement.type !== 'fieldOfStudy' && requirement.type !== 'jobLevel') 
                ? '\n\n' + requirement.requirementRevisions[0].name + ': ' + context.state.responses[index].value.value +
                  (requirementsMap 
                    ? requirementsMap.filter(req => req.requirementId === requirement.id)[0]?.isMatching ? ' (Y)' : ' (N)'
                    : '') 

                : (requirement.type === 'fieldOfStudy')
                  ? '\n\n' + requirement.requirementRevisions[0].name + ': ' + 
                  getFieldOfStudyResponse(context.state.responses, context.state.fieldsOfStudyOptions) +
                  (requirementsMap 
                    ? requirementsMap.filter(req => req.requirementId === 'FieldOfStudy')[0]?.isMatching ? ' (Y)' : ' (N)'
                    : '') 
                  : '\n\n' + requirement.requirementRevisions[0].name + ': ' + 
                  context.state.responses.filter(res => res.value.type === 'jobLevel')[0].value.value.name +
                  (requirementsMap 
                    ? requirementsMap.filter(req => req.requirementId === 'jobLevel')[0]?.isMatching ? ' (Y)' : ' (N)'
                    : '') 
            );
          }).join('') 
        }>
      </CopyText>

    </div>
    </div>

    }
    </AppContext.Consumer>
  );
};
export default ResultsGhireCopy;

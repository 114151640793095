import React, {Component} from 'react';
import AppContext from '../AppContext';
import Select, {components} from 'react-select';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


class SelectDestinationCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      selectedCountry: null,
    };
  }

  returnCountryOptions(name, destinationCountries){
    let options =[];
    for(const country of destinationCountries){
      for(const region of country.regions){
        options.push({
          label: country.regions.length > 1 ? (country.name + ' - ' + region.name) : (country.name), 
          value: name, 
          response: {region: region, country: country}});
      }
    }
    return options;
  }

  returnDefaultValue(stateValue, options) {
    if(stateValue){
      const regionOptions = this.returnCountryOptions('selectedDestinationRegion', options);
      const optionToReturn = regionOptions.find(option => option.label.includes(stateValue));
      return optionToReturn;
    } else {
      return null;
    }
  }

  render() {

    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          <ExpandMoreIcon className="blue-chevron-destination"/>
        </components.DropdownIndicator>
      );
    };

    return (
      <React.Fragment>
        <AppContext.Consumer>
          {(context) => 
            context.state.destinationCountries && 
              <Select
                value={this.props.defaultValue ? this.props.defaultValue : this.state.value}
                placeholder={'Where will the role be based?'}
                inputId={'select-destination-country'}
                className="destination-react-select-container" 
                classNamePrefix="destination-react-select"
                components={{DropdownIndicator}}
                options={this.returnCountryOptions('selectedDestinationRegion', context.state.destinationCountries)} 
                onChange={ (event) => {
                  context.updateState('selectedDestinationRegion', event.response);
                }}
                defaultValue={this.returnDefaultValue}
              />
          }
        </AppContext.Consumer>
      </React.Fragment>
    );
  }
}

export default SelectDestinationCountry;

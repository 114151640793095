import React, {Component, Fragment} from 'react';
import AppContext from '../AppContext';
import ResultsEligibility from '../ResultsEligibility';
import ResultsFullAssessmentRequired from '../ResultsFullAssessmentRequired';
import ResultsPermit from '../ResultsPermit';
import ResultsProcessingTime from '../ResultsProcessingTime';
import ResultsStartDate from '../ResultsStartDate';
import ResultsAttachments from '../ResultsAttachments';
import ResultsSections from '../ResultsSections';
import ResultsShare from '../ResultsShare';
import Loading from '../Loading';
import Slide from '@material-ui/core/Slide';

class Results extends Component {
  componentDidMount() {
    this.props.updateAnalyticsPath('/results');
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <AppContext.Consumer>
        {(context) =>
            context.state.isLoadingResults
            ? <Loading results={true} />
            : <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
                <div className="container pt-5 pb-5">
                  { context.state.eligibilityResult.length > 0 && 
                    (context.state.eligibilityResult[0].isFailingBasedOnFieldOfStudy || context.state.eligibilityResult[0].isManuallyAssessed)
                    ? <ResultsFullAssessmentRequired customOutput={context.state.eligibilityResult[0].manualAssessmentResultText} selectedDestinationRegion={context.state.selectedDestinationRegion} />
                    : <Fragment>
                        <ResultsEligibility />
                        <ResultsPermit />
                        <ResultsProcessingTime />
                        <ResultsStartDate />
                        <ResultsSections />
                        {context.state.eligibilityResult.length > 0  && <ResultsShare />}
                        <ResultsAttachments />
                        {
                          (context.state.ineligibleResult.length > 0 || context.state.eligibilityResult.length > 0) &&
                          <React.Fragment>
                            {context.state.eligibilityResult.length > 0 && <hr/>}
                            <div className="d-flex justify-content-start mt-3">
                              {/* <button className="btn results-button requirement-question-button mt-4 mr-4" onClick={() => { window.print(); context.trackAnalyticsEvent('clicked', 'Print assessment results', 'button'); }}>SAVE AS PDF</button> */}
                              <button className="btn results-button requirement-question-button mt-4 mr-4" onClick={()=> {context.resetAssessment(); window.location.replace('/#/');}}>CLOSE</button>
                            </div>
                            <div className="d-flex justify-content-start asessment-result-info">
                              <p className="asessment-result-info-text"><b>ASSESSMENT DATE: </b>{new Date().toLocaleString()}</p>
                              { context.state.eligibilityResult[0] 
                                ? <p className="asessment-result-info-text"><b>Google VisaCheck ID: </b>{context.state.eligibilityResult[0].assessmentId}</p>
                                : <p className="asessment-result-info-text"><b>Google VisaCheck ID: </b>{context.state.ineligibleResult[0].assessmentId}</p>
                              }
                              {
                                context.state.gHireId && <p className="asessment-result-info-text"><b>gHire ID: </b>{context.state.gHireId}</p>
                              }
                            </div>
                          </React.Fragment>
                        }
                      </Fragment>
                  }
                </div>
              </Slide>
              }
      </AppContext.Consumer>
    );
  }
}

export default Results;

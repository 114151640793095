import React from 'react';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';

const ExpansionPanel = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderBottom: '1px solid #d1d1d1',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const HelpAndSupport = () => {
  return (
    <div className="help-and-support-container d-flex">
      <h3 className="faq-title pt-3 pb-3">{'Google VisaCheck Help & Feedback'}</h3>
      <ExpansionPanel defaultExpanded={true} className="help-section-panel" >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className="results-expand-more"/>}>
          <h6 className="faq-section-title pt-1">What is Google VisaCheck?</h6>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className="results-description support">
            <div className="faq-content">
                Google VisaCheck is an aid to recruiters to determine early immigration eligibility in candidates, immigration processing times and the best possible start date. The assessment aims to optimise the hiring experience for both candidates and recruiters, providing early insight into any immigration actions or roadblocks.
                Visit go/visacheckhelp for any further FAQs.
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      <div className="row mt-5">
        <div className="d-flex justify-content-center mb-2 pl-4 pr-4 align-items-center w-100">
          <p className="help-and-support-recruiter-title description">
            For support using Google VisaCheck, questions on the results or any other immigration query please contact <a href = "mailto: immigration@google.com">immigration@google.com</a>.
          </p>
        </div>
      </div>
      <div className="mb-2 pl-4 pr-4 w-100"style={{
        display: 'flex', 
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '0px !important'
      }}>
        <div style={{textAlign:'left', paddingLeft:'2em'}}>
          <p className="help-and-support-recruiter-title description">
            We would love to hear your feedback on Google VisaCheck:
          </p>
          <li className="help-and-support-recruiter-title description">
          Please provide your feedback about the newest Google VisaCheck US enhancement  <a target='_blank' rel='noopener noreferrer' href='https://docs.google.com/forms/d/e/1FAIpQLScIcsgDPxF5i-fpb58JknJ3GT-ElLSG2Z51sI3h09ljc9Azng/viewform'> here</a>
          </li>
          <li className="help-and-support-recruiter-title description">
          Please provide general feedback about Google VisaCheck <a target='_blank' rel='noopener noreferrer' href='https://forms.gle/VgTsRhMzEYkMKEfR7'> here</a>
          </li>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;

import React, {Fragment} from 'react';
import AppContext from '../components/AppContext';
import ResultsCandidateShare from '../components/ResultsCandidateShare';
import ResultsGhireCopy from '../components/ResultsGhireCopy';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';

// Props for "Show In Eligible Page" = true -> Show the PDF export part.
const ResultsShare = ({ineligiblePermit, requirementsMap}) => {
  return (
    <AppContext.Consumer>
      {(context) => 
        <Fragment>
          { (context.state.eligibilityResult[0] !== null || context.state.ineligibleResult[0] !== null) &&
            <div className="vc__share-result-container mb-5">
              <div className="d-flex align-items-center about-permit-heading">
                <ShareOutlinedIcon className="vc__share-icon mr-2"/> SHARING THIS RESULT
              </div>
              <div className="d-flex">
                { context.state.eligibilityResult.length > 0 && 
                  <ResultsCandidateShare 
                    isStartDateShown={context.state.showStartDate}
                    toggleStartDateFromPdf={context.setIsStartDateHidden} />
                }
                <ResultsGhireCopy 
                  ineligiblePermit={ineligiblePermit}
                  requirementsMap={requirementsMap}
                />
              </div>
            </div>
          }
        </Fragment>
      }
    </AppContext.Consumer>
  );
};
export default ResultsShare;

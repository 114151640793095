import React from 'react';
import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined';
import {convertFromRaw} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

const ResultsFullAssessmentRequired = ({ customOutput, selectedDestinationRegion }) => {
  const convertContent = (content) => {
    const contentState = convertFromRaw(JSON.parse(content));
    let html = stateToHTML(contentState);
    return html;
  };

  return (
    <div className="row">
      <div className="col-10 offset-2 d-flex align-items-center">
        <h1 className="results-eligibility-heading">Full assessment required</h1>
        <ErrorOutlineOutlined className="results-failure-x vc__contact-immigration ml-3" />
      </div>

      <div className="col-10 offset-2">
        {customOutput !== ''
          ? <p className="results-eligibility-description pt-3 pb-4" dangerouslySetInnerHTML={{__html: convertContent(customOutput)}}></p>
          : <p className="results-eligibility-description pt-3 pb-4">
          The candidate’s position needs a detailed review by an immigration specialist to determine work eligibility.  Please complete this <a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__goto.google.com_immigration-2Dhelp&d=DwMFaQ&c=6HFirGB4LnuU4asVt8kNcMhC5SvBtxbjoaC4-rMbhtk&r=K0XgVOEvobiYfgyAsJ_oLqdYpX4uiKt2F60PUrGpf4s&m=Gsa7BU1_mT4acQ51Y_zRgqmgqhZYHIyejp387k7DdLZyYghYFecLBrCYUqfXuBrN&s=r3OHV0pXcknDDK1OqK-aY2zcPvouoac7snxIKgJvTBo&e=">template</a> to request a detailed assessment.
          </p>
        }
      </div>

      <div className="col-10 offset-1 d-flex justify-content-around">
      <div class="mt-4">
          <button class="btn btn-secondary requirement-question-button" style={{backgroundColor:'rgb(135, 202, 70)'}}><a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__goto.google.com_immigration-2Dhelp&d=DwMFaQ&c=6HFirGB4LnuU4asVt8kNcMhC5SvBtxbjoaC4-rMbhtk&r=K0XgVOEvobiYfgyAsJ_oLqdYpX4uiKt2F60PUrGpf4s&m=Gsa7BU1_mT4acQ51Y_zRgqmgqhZYHIyejp387k7DdLZyYghYFecLBrCYUqfXuBrN&s=r3OHV0pXcknDDK1OqK-aY2zcPvouoac7snxIKgJvTBo&e=" style={{textDecoration: 'none', color:'white'}}>COMPLETE TEMPLATE</a></button>
        </div>
        <div class="mt-4">
          <button class="btn requirement-question-button" onClick={() => window.location.replace('/#/')}>EXIT ASSESSMENT</button>
        </div>
      </div>
    </div>
  );
};

export default ResultsFullAssessmentRequired;

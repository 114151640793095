import React, { Component } from 'react';
import Sidebar from '../Sidebar';
import JobPositions from '../JobPositions';
import RequirementResponses from '../RequirementResponses';
import Slide from '@material-ui/core/Slide';
import SelectSourceCountry from '../SelectSourceCountry';
import HorizontalStepper from '../HorizontalStepper';
import ExistingVisa from '../ExistingVisa';
import SelectCitizenship from '../SelectCitizenship';
import AppContext from '../AppContext';
import AdditionalQuestion from '../AdditionalCitizenshipQuestion';

class Assessment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSelectingCitizenship: false,
      isSelectingCitizenshipSubQuestion: false,
      isSelectingSource: false,
      isSelectingJobPosition: false,
      isInRequirementResponseSection: false,
      isInExistingVisaSection: true,
      isInSummary: false,
      requirementIndex: 0,
      stepIndex: 1,
      steps: this.returnRequirementSteps()
    };
  }

  componentDidMount(){
    if(!this.props.selectedDestinationRegion){
      window.location.replace('/#/');
    } else{
      this.props.updateState('startTime', Date.now());
    }
    this.props.updateAnalyticsPath('/assessment');
  }

  returnRequirementSteps(){
    const requirementSteps= [0, 1, 2, 3];
    this.props.requirements.forEach(() => requirementSteps.push(requirementSteps.length + 1));

    return requirementSteps;
  }

  resetSelectingState = (name) => {
    this.setState({
      isSelectingSource: name === 'isSelectingSource',
      isSelectingJobPosition: name === 'isSelectingJobPosition',
      isInRequirementResponseSection: name === 'isInRequirementResponseSection',
      isInExistingVisaSection: name === 'isInExistingVisaSection',
      isSelectingCitizenship: name === 'isSelectingCitizenship',
      isSelectingCitizenshipSubQuestion: name === 'isSelectingCitizenshipSubQuestion',
    });
  }

  updateSelectingState = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  navigateToRequirement = (requirementIndex) => {
    if (requirementIndex !== this.state.requirementIndex) {
      this.setState({
        requirementIndex,
        stepIndex: (requirementIndex + 2),
      });
    }
  }

  returnToSummary = (isInResultsPage) => {
    if (isInResultsPage && this.state.isInExistingVisaSection) {
      this.setState({
        isInExistingVisaSection: false,
        isSelectingSource: false,
        isSelectingJobPosition: false,
        isInRequirementResponseSection: true,
        requirementIndex: this.props.requirements.length+1,
      });
    }
  }

  render() {
    return (
      <AppContext.Consumer>
        {(context) =>
          <React.Fragment>
            <HorizontalStepper steps={this.state.steps} activeStep={this.state.stepIndex} />
            <div className="row w-100">
              <Slide timeout={{enter:300}} direction="up" in={true} mountOnEnter unmountOnExit>
                <div className="col-sm-10 col-xs-12">
                  {this.returnToSummary(context.state.isInResultsPage)}
                  {
                    this.state.isInExistingVisaSection &&
                    <ExistingVisa updateSelectingState={this.updateSelectingState} />
                  }
                  {
                    this.state.isSelectingSource &&
                    <SelectSourceCountry
                      updateSelectingState={this.updateSelectingState}
                      isSelectingSource={this.state.isSelectingSource}
                    />
                  }
                  {
                    this.state.isSelectingJobPosition &&
                    <JobPositions updateSelectingState={this.updateSelectingState} />
                  }
                  {
                    this.state.isSelectingCitizenship &&
                    <SelectCitizenship updateSelectingState={this.updateSelectingState} />
                  }
                  {                    
                    this.state.isSelectingCitizenshipSubQuestion &&
                    <AdditionalQuestion updateSelectingState={this.updateSelectingState}/>
                  }
                  {
                    this.state.isInRequirementResponseSection && this.state.steps &&
                    <RequirementResponses
                      isInSummary={this.state.isInSummary}
                      stepIndex={this.state.stepIndex}
                      requirementIndex={this.state.requirementIndex}
                      updateSelectingState={this.updateSelectingState}
                    />
                  }
                </div>
              </Slide>
              <div className="col-sm-2">
                <Sidebar
                  updateSelectingState={this.updateSelectingState}
                  resetSelectingState={this.resetSelectingState}
                  isSelectingSource={this.state.isSelectingSource}
                  isSelectingJobPosition={this.state.isSelectingJobPosition}
                  isSelectingCitizenship={this.state.isSelectingCitizenship}
                  isSelectingCitizenshipSubQuestion={this.state.isSelectingCitizenshipSubQuestion}
                  isInRequirementResponseSection={this.state.isInRequirementResponseSection}
                  isSelectingGHireId={this.state.isSelectingGHireId}
                  currentPosition={this.state.requirementIndex}
                  navigateToRequirement={this.navigateToRequirement}
                />
              </div>
            </div>
          </React.Fragment>
        }
      </AppContext.Consumer>
    );
  }
}

export default Assessment;

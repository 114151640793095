import React, {Component} from 'react';
import AppContext from '../AppContext';

class HorizontalStepper extends Component{
  render(){
    return (
      <AppContext.Consumer>
        {(context) =>  {
          const totalSteps= this.props.steps.length + 1; // includes existing visa, source region, job position, citizenship, requirements.length, ghire ID
          const completedSteps =
          (context.state.isCompletedExistingVisa ? 1 : 0)
          + (context.state.selectedSourceRegion ? 1 : 0)
          + (context.state.selectedJobPosition ?  1 : 0)
          + (getCitizenshipSteps())
          // + (context.state.selectedCitizenship ? 1 : 0)
          + findCompletedRequirements()
          + (context.state.gHireId ? 1 : 0);

          function getCitizenshipSteps(){
            let citizenshipSteps = 0;
            if (context.state.selectedCitizenship){
              if(context.state.selectedDestinationRegion.country.id === 1 && context.state.selectedCitizenship.country.name === 'Not disclosed') {
                citizenshipSteps += 0.5;
                if (context.state.selectedCitizenshipSpecificVisa) {
                  citizenshipSteps += 0.5;
                }
              } else {
                citizenshipSteps += 1;
              }
            }
            return citizenshipSteps;
          }

          function findCompletedRequirements(){
            let completedRequirements = 0;
            for(const response of context.state.responses){
              if(response.value.id){
                completedRequirements += 1;
              }
            }
            return completedRequirements;
          }
          return (
            <React.Fragment>
              <div className="d-flex horizonal-stepper-container w-100">
                <div className="horizonal-stepper blue"></div>
                <div className="horizonal-stepper red"></div>
                <div className="horizonal-stepper yellow"></div>
                <div className="horizonal-stepper green"></div>
              </div>
              <div style= {{width: (100 - ((completedSteps/totalSteps) * 100)) + '%'}} className="d-flex horizonal-stepper-container cover"></div>
            </React.Fragment>
          );
        }
        }
      </AppContext.Consumer>
    );
  }
}

export default HorizontalStepper;

import React, { Component } from 'react';
import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import AppContext from '../AppContext';


class ExistingVisaResult extends Component {
  render() {
    return (
      <AppContext.Consumer>
        {(context) =>
          <div className="p-3 row h-100 d-flex justify-content-center align-items-center">
            {this.props.success ?
              <div>
                <div className="row">
                  <div className="d-flex align-items-center">
                    <h1 className="results-eligibility-heading">{context.state.nextNode ? context.state.nextNode.title : 'Candidate is eligible'}</h1>
                    <CheckCircleOutlined className="results-success-check ml-3" />
                  </div>
                </div>
                <div className="row">
                  <div className="results-eligibility-description">
                    <div dangerouslySetInnerHTML={{__html: context.state.nextNode ? context.state.nextNode.text : 'This eligibility assessment is indicative only and will require a full consultation if the candidate proceeds with the work permit.'}} />
                  </div>
                </div>
                <div className="row d-flex justify-content-start">
                    {/* <button class="btn btn-secondary requirement-question-button mt-2" style={{backgroundColor:'rgb(135, 202, 70)'}}><a href="https://goto.google.com/template1visacheck" style={{textDecoration: 'none', color:'white'}}>COMPLETE TEMPLATE</a></button>  removed this btn per George's ticket 2354218 */}
                    <button className="btn requirement-question-button mt-2" onClick={() => {context.resetAssessment(); window.location.replace('/#/'); context.trackAnalyticsEvent('clicked',('Exit assessment on: existing-visa-candidate-eligible, '+ context.state.selectedDestinationRegion.country.name), 'button');}}>EXIT ASSESSMENT</button>
                </div>
              </div>
              :
              <div>
                <div className="row">
                  <div className="d-flex align-items-center">
                    <h1 className="results-eligibility-heading">{this.props.endNode.title}</h1>
                    <ErrorOutlineOutlined className="results-failure-x vc__contact-immigration ml-3" />
                  </div>
                </div>

                <div className="row">
                  <div className="d-flex results-eligibility-description">
                    <div className="" dangerouslySetInnerHTML={{__html: this.props.endNode.text}} />
                  </div>
                </div>
                <div className="row d-flex justify-content-between ml-5" style={{marginRight: '95px'}}>
                    <button class="btn btn-secondary requirement-question-button mt-2" style={{backgroundColor:'rgb(135, 202, 70)'}}><a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__goto.google.com_immigration-2Dhelp&d=DwMFaQ&c=6HFirGB4LnuU4asVt8kNcMhC5SvBtxbjoaC4-rMbhtk&r=K0XgVOEvobiYfgyAsJ_oLqdYpX4uiKt2F60PUrGpf4s&m=Gsa7BU1_mT4acQ51Y_zRgqmgqhZYHIyejp387k7DdLZyYghYFecLBrCYUqfXuBrN&s=r3OHV0pXcknDDK1OqK-aY2zcPvouoac7snxIKgJvTBo&e=" style={{textDecoration: 'none', color:'white'}}>COMPLETE TEMPLATE</a></button>
                    <button className="btn requirement-question-button mt-2" onClick={() => {context.resetAssessment(); window.location.replace('/#/'); context.trackAnalyticsEvent('clicked',('Exit assessment on: existing-visa-contact-immigration, '+ context.state.selectedDestinationRegion.country.name), 'button');}}>EXIT ASSESSMENT</button>                  
                </div>
              </div>
            }
          </div>
        }
      </AppContext.Consumer>
    );
  }
}

export default ExistingVisaResult;

import React, { Fragment } from 'react';
import AppContext from './AppContext';
import RequirementQuestion from './RequirementQuestion';

const AdditionalQuestion = ({updateSelectingState}) => {
  const buildVisaDropdown = (name, citizenshipSpecificVisas) => {
    let options = [];

    for (const visa of citizenshipSpecificVisas) {
      const currentVisaName = visa.name.trim();
      if(!options.filter(option => option.responseValue === currentVisaName).length > 0) {
        const optionLabel = currentVisaName + ' - ' + visa.citizenships.join(', ');
        options.push({
          label: optionLabel,
          value: name,
          responseValue: currentVisaName, // Can be changed to ID once work permits become grouped
        });
      }
    }

    options.push({
      label: 'None of the above',
      value: 'N/A',
      responseValue: 'N/A', // Can be changed to ID once work permits become grouped
    });

    return options;
  };

  return (
    <AppContext.Consumer>
      {(context) =>
        <Fragment>
          <RequirementQuestion
            requirementResponse={false}
            options={buildVisaDropdown('selectedCitizenshipSpecificVisa', context.state.citizenshipSpecificVisas)}
            placeholder="Select Visa"
            question="Does the candidate believe they're eligible for one of these visas based on their citizenship?"
            description="Citizens of Australia, Chile, Singapore, Mexico, and Canada may be eligible for additional visas. Please select one of the options below."
            dropdown={true}
            defaultValue={context.state.selectedCitizenshipSpecificVisa}
            id="select-citizenship-visa"
            label="Select Citizenship Visa"
            onChange={(event) => {
              context.updateState('selectedCitizenshipSpecificVisa', event);
            }}
            onClickContinue={() => {
              updateSelectingState('isSelectingCitizenshipSubQuestion', false);
              updateSelectingState('stepIndex', 4);
              updateSelectingState('isInRequirementResponseSection', true);
            }}
            onClickBack={() => {
              updateSelectingState('isSelectingCitizenshipSubQuestion', false);
              updateSelectingState('isSelectingCitizenship', true);
              updateSelectingState('stepIndex', 3);
            }}
          />
        </Fragment>
      }
    </AppContext.Consumer>
  );
};

export default AdditionalQuestion;
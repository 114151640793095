import React, { Component, Fragment } from 'react';
import AppContext from '../AppContext';
import ExistingVisaQuestion from '../ExistingVisaQuestion';
import RequirementQuestion from '../RequirementQuestion';
import ExistingVisaNodes from '../ExistingVisaNodes';
import Modal from '@material-ui/core/Modal';
import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined';
import Close from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import ExistingVisaResult from '../ExistingVisaResult';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Loading from '../Loading';

class ExistingVisa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isInExistingVisaDecisionTree: false,
      isInSelectingCurrentVisa: false,
      valueSelected: null,
      open:false,
      isHasSupportedVisa:false,
      visaResult: null,
      endNode: null,
      isHasCurrentVisa: false,
      isContinuingAssessment: false
    };
  }

  handleOpen = (isHasSupportedVisa) => {
    this.setState({
      open: true,
      isHasSupportedVisa: isHasSupportedVisa
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  returnExistingVisaOptions(stateVisas) {
    let options =[];
    if(stateVisas){
      for(const visa of stateVisas){
        options.push({
          label: visa.name,
          value: 'selectedExistingVisa',
          response: visa
        });
      }
    }
    return options;
  }

  returnNodeEdgeOptions(stateEdges) {
    let options =[];
    if(stateEdges){
      for(const edge of stateEdges){
        options.push({
          label: edge.text,
          value: edge,
          response: edge
        });
      }
    }
    return options;
  }

  handleCountryName = (name) =>{
    if (name ==='United States (USA)'){
      return ('Does the candidate have a valid visa to work in ' + name + ' or hold valid student status in the USA?');
    }else {
      return ('Does the candidate have a valid visa to work in ' + name + '?');
    }
  }
  continueAssessment = () => {
    this.props.updateSelectingState('isInExistingVisaSection', false);
    this.props.updateSelectingState('isSelectingSource', true);
  }

  updateState = (name, value) => {
    this.setState({
      [name]: value
    });
  }

  handleVisaStatusChange = (event) => {
    this.setState({
      isHasCurrentVisa: event.target.value === 'true'
    });
  }

  render() {
    return (
      <AppContext.Consumer>
        {(context) => {
          return (
            <React.Fragment>
              {
                !this.state.isInExistingVisaDecisionTree && !this.state.isInSelectingCurrentVisa && context.state.selectedDestinationRegion &&
                <RequirementQuestion
                  requirementResponse={false}
                  question={this.handleCountryName(context.state.selectedDestinationRegion.country.name)}
                  description=""
                  dropdown={false}
                  id="select-if-user-holds-existing-visa"
                  label="Select if user holds existing visa"
                  customInput={true}
                >
                  {/* RADIO BUTTONS */}
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="select-if-candidate-hase-current-visa" name="current-visa-radio" value={this.state.isHasCurrentVisa} onChange={this.handleVisaStatusChange} row>
                      <FormControlLabel
                        value={true}
                        control={<Radio color="primary" className="vc__visa-radio" />}
                        label="YES"
                        labelPlacement="end"
                        className="visa-radio-form pr-5"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio color="primary" className="vc__visa-radio"/>}
                        label="NO"
                        labelPlacement="end"
                        className="visa-radio-form pl-3"
                      />
                    </RadioGroup>
                  </FormControl>

                  {/* CONTINUE BUTTON */}
                  <div className="d-flex justify-content-end w-100">
                    {
                      context.state.isLoadingExistingVisas
                        ? <Loading />
                        : <button
                            aria-label="Yes - current visa holder"
                            type="button"
                            className="mr-5 requirement-question-button"
                            onClick={() => {
                              if (this.state.isHasCurrentVisa) {
                                context.updateState('isExistingVisaHolder', true);
                                context.updateState('isCompletedExistingVisa', false);

                                if (context.state.existingVisas && context.state.existingVisas.length > 0 && context.state.selectedDestinationRegion.country.name === 'United States (USA)'){
                                  this.handleOpen(true);
                                  context.trackAnalyticsEvent('clicked',('Candidate has supported existing visa in: '+ context.state.selectedDestinationRegion.country.name), 'button');
                                } else {
                                  this.handleOpen(false);
                                  context.trackAnalyticsEvent('clicked',('Candidate has unsupported existing visa in: '+ context.state.selectedDestinationRegion.country.name), 'button');
                                }
                              } else {
                                context.trackAnalyticsEvent('clicked',('Candidate is not an existing visa holder in: '+ context.state.selectedDestinationRegion.country.name), 'button');
                                context.updateState('isExistingVisaHolder', false);
                                this.continueAssessment();
                                context.updateState('isCompletedExistingVisa', true);
                              }
                            }}
                          >CONTINUE</button>
                    }
                  </div>
                </RequirementQuestion>
              }

              {/* THE EXISTING VISAS MODAL */}
              <Modal
                className="vc__existing-visa-modal"
                aria-labelledby ="existing-visa-modal-title"
                aria-describedby="existing-visa-modal"
                open={this.state.open}
                onClose={this.handleClose}
              >
                <Fade in={this.state.open}>
                  <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                    <div className="shadow vc__existing-visa-modal-content">
                      {
                        context.state.isLoadingExistingVisas
                          ? <Loading results={true}/>
                          : <React.Fragment>
                              {/* THE CLOSE BUTTON */}
                              <div className="d-flex justify-content-end">
                                <Close onClick={() => {
                                  if (this.state.visaResult !== null || this.state.endNode){
                                    context.resetAssessment();
                                    window.location.replace('/#/');
                                  } else if ((this.state.endNode || this.state.visaResult !== null) || this.state.isContinuingAssessment){
                                    this.continueAssessment();
                                    this.handleClose();
                                  } else {
                                    context.state.nextNode = null;
                                    context.updateState('answeredNodes', []);
                                    this.updateState('isInExistingVisaDecisionTree', false);
                                    this.updateState('isInSelectingCurrentVisa', false);
                                    this.handleClose();
                                  }
                                }}
                                className="vc__modal-close-icon" />
                              </div>
                              {
                                this.state.visaResult === null &&
                                <React.Fragment>
                                  {/* CANDIDATE SELECTS EXISITING VISA */}
                                  {
                                    this.state.isInSelectingCurrentVisa &&
                                    <ExistingVisaQuestion
                                      modal={true}
                                      requirementResponse={false}
                                      options={this.returnExistingVisaOptions(context.state.existingVisas)}
                                      question="What visa does the candidate currently hold?"
                                      description="If candidate is in possession of more than one work authorization status for the destination country, please select 'multiple' from the list below."
                                      id="select-current-visa"
                                      label="Select current visa"
                                      onChange={(event) => {
                                        this.setState({valueSelected: event});
                                        context.updateSelectedExistingVisa(event);
                                      }}
                                      onClickContinue ={() => {
                                        context.fetchFirstNode(this.state.valueSelected);
                                        this.setState({
                                          valueSelected: null,
                                          isInExistingVisaDecisionTree: true,
                                          isInSelectingCurrentVisa: false
                                        });
                                      }}
                                    />
                                  }

                                  {
                                    this.state.isInExistingVisaDecisionTree && (context.state.nextNode !== null) &&
                                    <ExistingVisaNodes
                                      answeredNodes={context.state.answeredNodes}
                                      updateNodeState={this.updateNodeState}
                                      updateContextState={context.updateState}
                                      valueSelected={this.state.valueSelected}
                                      fetchNextNode={context.fetchNextNode}
                                      continueAssessment={this.continueAssessment}
                                      updateState={this.updateState}
                                      node={context.state.nextNode}
                                    />
                                  }

                                  {
                                    !this.state.isInExistingVisaDecisionTree && !this.state.isInSelectingCurrentVisa &&
                                    <div className="d-flex justify-content-center align-items-center vc__existing-visa-node h-100">
                                      <div className="row">
                                        <div className="">
                                        <div className="row">
                                          {
                                            !this.state.isContinuingAssessment
                                              ? <h1 id="existing-visa-modal-title" className="results-eligibility-heading">{this.state.isHasSupportedVisa ? 'Current Visa Review Required' : 'Further assessment needed'}</h1>
                                              : <h1 id="existing-visa-modal-title" className="results-eligibility-heading">{context.state.nextNode.hasOwnProperty('title') ? context.state.nextNode.title : 'New Permit Required'}</h1>
                                          }
                                          <ErrorOutlineOutlined className="results-failure-x vc__contact-immigration ml-3" />
                                        </div>

                                        <div className="row">
                                          {
                                            !this.state.isContinuingAssessment ?
                                              <div id="existing-visa-modal" className="results-eligibility-description pt-3 pb-4">
                                                {
                                                  this.state.isHasSupportedVisa
                                                    ? <Fragment>
                                                        <p>To determine if Google can sponsor your candidate for work authorization we need more details about their current visa. Please click OK to continue with the assessment.</p>
                                                      </Fragment>
                                                    : <Fragment>
                                                        The candidate’s current visa needs a detailed review by an immigration specialist to determine work eligibility.
                                                        {context.selectedDestinationRegion === 1 ? 'Please contact immigration support at ' + <a href="mailto:immigration@google.com">immigration@google.com</a> + '.': ''}
                                                      </Fragment>
                                                }
                                                {
                                                  this.state.isHasSupportedVisa
                                                  ? <div className="d-flex justify-content-end mt-5">
                                                      <button className="btn requirement-question-button" onClick={() => this.setState({ isInSelectingCurrentVisa: true })}>OK</button>
                                                    </div>
                                                  : <div className="d-flex justify-content-start mt-4">
                                                      {context.selectedDestinationRegion === 1 
                                                      ? <button className="btn requirement-question-button" onClick={this.handleClose}>EXIT ASSESSMENT</button>
                                                      : <a href="https://goto.google.com/template2visacheck"><button className="btn requirement-question-button">REQUEST DETAILED ASSESSMENT</button></a>
                                                      }
                                                    </div>
                                                }
                                              </div>
                                              :
                                              <div id="existing-visa-modal" className="results-eligibility-description visa pt-4">
                                                {
                                                  context.state.nextNode.hasOwnProperty('text')
                                                  ? <div className="" dangerouslySetInnerHTML={{__html: context.state.nextNode.text}} />
                                                  : <Fragment>
                                                      <p>Based on the information provided the existing visa may not be transferable. Click continue below to assess candidate for a new visa.</p>
                                                      <p>If your candidate is an existing Googler please visit go/usvisa-changenotification to review whether the employment change will impact their status.</p>
                                                      <br />
                                                      <h5>For questions or further information, please contact <a href="mailto:immigration@google.com">immigration@google.com</a></h5>
                                                    </Fragment>
                                                }
                                                <div className="d-flex justify-content-end mt-5">
                                                  <button className="btn requirement-question-button" onClick={() => this.continueAssessment()}>CONTINUE</button>
                                                </div>
                                              </div>
                                          }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  }
                                </React.Fragment>
                              }
                              {
                                (this.state.endNode || this.state.visaResult !== null) &&
                                <Slide timeout={{enter:300}} direction="up" in={true} mountOnEnter unmountOnExit>
                                  <ExistingVisaResult success={this.state.visaResult} endNode={this.state.endNode}></ExistingVisaResult>
                                </Slide>
                              }
                            </React.Fragment>
                      }
                    </div>
                  </div>
                </Fade>
              </Modal>
            </React.Fragment>
          );
        }}
      </AppContext.Consumer>
    );
  }
}

export default ExistingVisa;

import React, { Component } from 'react';
import AppContext from '../AppContext';
import Lock from '@material-ui/icons/Lock';
import Slide from '@material-ui/core/Slide';

export default class ProgressBar extends Component {
  isCurrentStep(currentPosition, mapIndex) {
    return currentPosition === mapIndex;
  }

  isRequirementAnswered(requirementId, responses) {
    if (responses.length > 0) {
      let result = responses.find(res => res.requirementId === requirementId);
      if (result.value.value) {
        return true;
      }
    }

    return false;
  }

  isCitizenshipAnswered(context){
    if (context.state.selectedCitizenship) {
      if (context.state.selectedCitizenship.country.name === 'Not disclosed' && context.state.selectedDestinationRegion.country.id === 1) {
        return context.state.selectedCitizenshipSpecificVisa ? true : false;
      } else {
        return true;
      }
    }
    return false;
  }

  render() {
    return (
      <AppContext.Consumer>
        {(context) =>
          <ul className="sidebar">
            <div className="sidebar-scroll">
              <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
                <li className={(!context.state.isCompletedExistingVisa ? 'current ' : 'finished ') + ' visa'}>
                  <div className="step" > 
                    <div className={'vc__icon-hidden ' + (context.state.isCompletedExistingVisa ? 'transition' : '') }>
                      <Lock className="lock-icon" />
                    </div>
                    <h6>Existing Visa</h6>
                  </div>
                  <hr />
                </li>
              </Slide>

              <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
                <li onClick={context.state.isCompletedExistingVisa ? (() => {this.props.resetSelectingState('isSelectingSource'); this.props.updateSelectingState('requirementIndex', 0);}) : null} className={(!context.state.isCompletedExistingVisa ? 'disabled ': '') + (this.props.isSelectingSource ? 'current ' : '')}>
                  <div className="step">
                    <div className={'vc__icon-hidden ' + (context.state.selectedSourceRegion ? 'transition' : '') }>
                      <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                      </svg>
                    </div>
                    <h6>Source Country</h6>
                  </div>
                  <hr />
                </li>
              </Slide>

              <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
                <li onClick={context.state.isCompletedExistingVisa ? (() => {this.props.resetSelectingState('isSelectingJobPosition'); this.props.updateSelectingState('requirementIndex', 0);}) : null} className={(!context.state.isCompletedExistingVisa ? 'disabled ': '') + (this.props.isSelectingJobPosition ? 'current ' : '')}>
                  <div className="step">
                    <div className={'vc__icon-hidden ' + (context.state.selectedJobPosition ? 'transition' : '') }>
                      <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                      </svg>
                    </div>
                    <h6>Job Position</h6>
                  </div>
                  <hr />
                </li>
              </Slide>

              <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
                <li onClick={context.state.isCompletedExistingVisa ? (() => {this.props.resetSelectingState('isSelectingCitizenship'); this.props.updateSelectingState('requirementIndex', 0);}) : null} className={(!context.state.isCompletedExistingVisa ? 'disabled ': '') + (this.props.isSelectingCitizenship || this.props.isSelectingCitizenshipSubQuestion ? 'current ' : '')}>
                  <div className="step">
                    <div className={'vc__icon-hidden ' + (this.isCitizenshipAnswered(context) ? 'transition' : '')}>
                      <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                      </svg>
                    </div>
                    <h6>Citizenship</h6>
                  </div>
                  <hr />
                </li>
              </Slide>
              {
                (this.props.isInRequirementResponseSection || (context.state.requirements && context.state.selectedDestinationRegion)) &&
                context.state.requirements && context.state.selectedDestinationRegion &&
                context.state.requirements.map((requirement, index) => {
                  return (
                    <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
                      <li className={this.props.isInRequirementResponseSection ? ((this.isCurrentStep(this.props.currentPosition, index) ? 'current ' : '') + (this.isRequirementAnswered(requirement.id, context.state.responses) ? 'complete' : '')) : '' + (!context.state.isCompletedExistingVisa ? ' disabled ': '')}  key={index} onClick={context.state.isCompletedExistingVisa ? (() => {this.props.resetSelectingState('isInRequirementResponseSection'); this.props.navigateToRequirement(index);}) : null}>
                        <div className="step">
                          <div className={'vc__icon-hidden ' + (this.isRequirementAnswered(requirement.id, context.state.responses) ? 'transition' : '')}>
                            <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                              <path fill="none" d="M0 0h24v24H0z"/>
                              <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                            </svg>
                          </div>
                          <h6 className={(this.props.isInRequirementResponseSection ? (this.isCurrentStep(this.props.currentPosition, index) ? 'current ' : '') : '')}>
                            {requirement.requirementRevisions[0].name}
                          </h6>
                        </div>
                        <hr />
                      </li>
                    </Slide>
                  );
                })
              }
              <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
                <li className={this.props.isInRequirementResponseSection ? ((this.isCurrentStep(this.props.currentPosition, context.state.requirements.length) ? 'current ' : '') + (context.state.gHireId ? 'complete' : '')) : '' + (!context.state.isCompletedExistingVisa ? ' disabled ': '')} onClick={context.state.isCompletedExistingVisa ? (() => {this.props.resetSelectingState('isInRequirementResponseSection'); this.props.navigateToRequirement(context.state.requirements.length); this.props.updateSelectingState('stepIndex',context.state.requirements.length+1); this.props.updateSelectingState('requirementIndex', context.state.requirements.length);}) : null}>
                  <div className="step">
                    <div className={'vc__icon-hidden ' + (context.state.gHireId ? 'transition' : '') }>
                      <svg aria-labelledby="answer successfully submitted checkmark" xmlns="http://www.w3.org/2000/svg" className="checkmark-icon" width="18" height="18" viewBox="0 0 24 24">
                        <path fill="none" d="M0 0h24v24H0z"/>
                        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                      </svg>
                    </div>
                    <h6 className={(this.props.isInRequirementResponseSection ? (this.isCurrentStep(this.props.currentPosition, context.state.requirements.length) ? 'current' : ''):'')}>
                        gHire App ID
                    </h6>
                  </div>
                  <hr />
                </li>
              </Slide>
              <Slide timeout={{enter:300}} direction="down" in={true} mountOnEnter unmountOnExit>
                <li className={(!context.state.isCompletedExistingVisa ? 'disabled ': '') +  (!this.props.isSelectingSource && !this.props.isSelectingJobPosition && (this.isCurrentStep(this.props.currentPosition, (context.state.requirements.length+1))) ? 'current ' : '') + (context.state.gHireId ? 'complete' : '')} onClick={context.state.isCompletedExistingVisa ? (() => {this.props.resetSelectingState('isInRequirementResponseSection'); this.props.navigateToRequirement(context.state.requirements.length+1); this.props.updateSelectingState('stepIndex',context.state.requirements.length+2); this.props.updateSelectingState('requirementIndex', context.state.requirements.length+1);}) : null}>
                  <div className="step">
                    <h6 className={(!this.props.isSelectingSource && !this.props.isSelectingJobPosition && this.isCurrentStep(this.props.currentPosition, (context.state.requirements.length+1))) ? 'current' : ''}>
                        Summary
                    </h6>
                  </div>
                  <hr />
                </li>
              </Slide>
            </div>
          </ul>
        }
      </AppContext.Consumer>
    );
  }
}
